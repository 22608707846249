import "./AddNewDFD.scss";

import Button from "react-bootstrap/Button";
// import Form from 'react-bootstrap/Form';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Navbar from "../../../components/Navbar/Navbar";

import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";

import { useContext, useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup } from "react-bootstrap";
import * as yup from "yup";
import AuthContext from "../../../contexts/auth";
import { InformacoesBasicas } from "../../../components/PCA/InformacoesBasicas/InformacoesBasicas";
import { RouteNamesIndex } from "../../../Dictionary/RouteNamesIndex";

import { DescricaoObjeto } from "../../../components/PCA/DescricaoObjeto/DescricaoObjeto";
import { JustificativaContratacao } from "../../../components/PCA/JustificativaContratacao/JustificativaContratacao";
import { EspecificacaoObjeto } from "../../../components/PCA/EspecificacaoObjeto/EspecificacaoObjeto";
import { FinalizarEtapa } from "../../../components/PCA/FinalizarEtapa/FinalizarEtapa";

// import { NumProcessoSkeleton } from "../../../components/skeletons/NumProcessoSkeleton";

export function AddNewDFD() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentRoute, setCurrentRoute] = useState({});

  async function LoadPage() {
    const EtapaRouteName = searchParams.get("etapa");

    if (EtapaRouteName !== null) {
      const EtapaRoute = RouteNamesIndex.find(
        (i) => i.route === `${EtapaRouteName}`
      );
      setCurrentRoute(EtapaRoute);
    } else {
      //   setLoad(true);
    }
  }
  useEffect(() => {
    LoadPage();
  }, [searchParams.get("etapa")]);

  function RenderCurrentRoute() {
    switch (currentRoute.route) {
      case "informacoes-basicas":
        return <InformacoesBasicas routeNumber={currentRoute.number} />;
        break;

      case "descricao-objeto":
        return <DescricaoObjeto routeNumber={currentRoute.number} />;
        break;
      case "justificativa-necessidade":
        return <JustificativaContratacao routeNumber={currentRoute.number} />;
        break;
      case "especficacao-objeto":
        return <EspecificacaoObjeto routeNumber={currentRoute.number} />;
        break;
      case "finalizar":
        return <FinalizarEtapa routeNumber={currentRoute.number} />;
      default:
        break;
    }
  }
  return (
    <>
      <Navbar
        titulo="Cadastrar DFD no PCA"
        link="/PCA"
        NomeLink="Plano de Contratação Anual
"
      />

      <main>
        <div className="container">{RenderCurrentRoute()}</div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
}
