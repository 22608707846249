import axios from "axios";
import api from "../services/api";
import { ModalErrorMessage } from "../components/ModalErrorMessage/ModalErrorMessage";

export default async function SubmitDFDServicos(
  token,
  codigoDFD,
  data,
  codigoObjeto = ""
) {
  const options = [
    { label: "Materiais", value: 1 },
    { label: "Serviços", value: 2 },
    { label: "Obras", value: 3 },
    { label: "Serviços de Engenharia", value: 4 },
    { label: "Locação Imóvel", value: 5 },
  ];
  const optionsPrioridade = [
    { label: "Baixo", value: 1 },
    { label: "Médio", value: 2 },
    { label: "Alto", value: 3 },
  ];
  const dataMaterial = {
    Descricao: data.Descricao,
    Categoria: 2,
    CodigoExterno: data.CodigoExterno,
    QuantidadePretendida: "1",
    GrauPrioridadeContratacao: data.GrauPrioridade.value,
    ValorTotalEstimado: data.ValorTotalEstimado,
    JustificativaPrioridade: data.justificativaPrioridade,
    MesPretendidoContratacao: data.MesContratacao.value,
    RenovacaoContrato: data.RenovacaoContrato,
    DespesaInformada: data.DespesaInformada,
    ParticipacaoRecursosExternos: data.ParticipacaoRecursosExternos,
    ValorUnitarioEstimado: data.ValorUnitario,
    TecnologiaInformacaoComunicacao: data.TecnologiaInformacao,
    Continuado: data.Continuado,
    CodigoDfd: codigoDFD,
    Codigo: codigoObjeto,
  };
  try {
    let resp = "";
    if (!codigoObjeto) {
      resp = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd`,
        dataMaterial,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      resp = await axios.put(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd`,
        dataMaterial,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dataMaterial.Categoria = options[dataMaterial.Categoria - 1].label;
      dataMaterial.GrauPrioridadeContratacao =
        optionsPrioridade[dataMaterial.GrauPrioridadeContratacao - 1].label;

      return dataMaterial;
    }
    return resp.data;
  } catch (error) {
    ModalErrorMessage(error);
  }
}
