import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { HiPencil } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthContext from "../../../contexts/auth";
import NavegacaoLateral from "../../NavegacaoLateral/NavegacaoLateral";
import { RouteNamesIndex } from "../../../Dictionary/RouteNamesIndex";
import CKeditorEtp from "../../CKeditor/CKeditorEtp";
import { Materiais } from "../CategoriaObjeto/Materiais/Materiais";
import { Servicos } from "../CategoriaObjeto/Servicos/Servicos";
import { Obras } from "../CategoriaObjeto/Obras/Obras";
import { ServicosEngenharia } from "../CategoriaObjeto/ServicosEngenharia/ServicosEngenharia";
import { LocacaoImovel } from "../CategoriaObjeto/LocacaoImovel/LocacaoImovel";
import "./EspecificacaoObjeto.scss";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SubmitDFDMaterial from "../../../functions/SubmitDFDMaterial";
import SubmitDFDEngenharia from "../../../functions/SubmitDFDEngenharia";
import SubmitDFDImovel from "../../../functions/SubmitDFDImovel";
import SubmitDFDObras from "../../../functions/SubmitDFDObras";
import SubmitDFDServicos from "../../../functions/SubmitDFDServicos";
import { formatServico } from "../../../functions/FormatObjectsToForm/FormatServico";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatObra } from "../../../functions/FormatObjectsToForm/FormatObra";
import { FormatEngenharia } from "../../../functions/FormatObjectsToForm/FormatEngenharia";
import { FormatMaterial } from "../../../functions/FormatObjectsToForm/FormatMaterial";
import { FormatImovel } from "../../../functions/FormatObjectsToForm/FormatImovel";
import { ModalErrorMessage } from "../../ModalErrorMessage/ModalErrorMessage";
import axios from "axios";
import { removerTags } from "../../../functions/RemoveHtml/RemoveHTML";
import { ModalPreviewObjetos } from "../../ModalPreviewObjetos/ModalPreviewObjetos";
import { ModalPreViewEtapa } from "../../ModalPreViewEtapa/ModalPreViewEtapa";
import { CheckStepsCompleted } from "../../../functions/CheckStepsCompleted";
import { ModalError } from "../../ModalError/ModalError";
export function EspecificacaoObjeto({ routeNumber }) {
  const initialValuesMaterial = {
    categoria: "Materiais",
    ValorUnitarioEstimado: "",
    QuantidadePretendida: "",
    GrauPrioridade: "",

    TecnologiaInformacao: false,
    RenovacaoContrato: false,
    DespesaInformada: false,
    ParticipacaoRecursosExternos: false,
    meses: [
      {
        nome: "Janeiro",
        value: 0,
      },
      {
        nome: "Fevereiro",
        value: 0,
      },
      {
        nome: "Março",
        value: 0,
      },
      {
        nome: "Abril",
        value: 0,
      },
      {
        nome: "Maio",
        value: 0,
      },
      {
        nome: "Junho",
        value: 0,
      },
      {
        nome: "Julho",
        value: 0,
      },
      {
        nome: "Agosto",
        value: 0,
      },
      {
        nome: "Setembro",
        value: 0,
      },
      {
        nome: "Outubro",
        value: 0,
      },
      {
        nome: "Novembro",
        value: 0,
      },
      {
        nome: "Dezembro",
        value: 0,
      },
    ],
  };
  const initialValueServicos = {
    Categoria: "Serviços",
    ValorTotalEstimado: "",
    MesContratacao: "",
    GrauPrioridade: "",

    TecnologiaInformacao: false,
    RenovacaoContrato: false,
    DespesaInformada: false,
    ParticipacaoRecursosExternos: false,
    Continuado: false,
  };
  const initialValueObras = {
    categoria: "Obras",
    ValorTotalEstimado: "",
    MesContratacao: "",
    GrauPrioridade: "",

    TecnologiaInformacao: false,
    RenovacaoContrato: false,
    DespesaInformada: false,
    ParticipacaoRecursosExternos: false,
    Continuado: false,
  };
  const initialValueEngenharia = {
    categoria: "Serviços de Engenharia",
    ValorTotalEstimado: "",
    MesInicio: "",
    GrauPrioridade: "",

    TecnologiaInformacao: false,
    RenovacaoContrato: false,
    DespesaInformada: false,
    ParticipacaoRecursosExternos: false,
    Continuado: false,
  };
  const initialValueImovel = {
    categoria: "Locação Imóvel",
    ValorTotalEstimado: "",
    Fornecedor: "",
    DataInicial: undefined,
    DataFinal: undefined,
    GrauPrioridade: "",

    RenovacaoContrato: false,
    DespesaInformada: false,
    ParticipacaoRecursosExternos: false,
  };
  const [valueRequester, setValueRequester] = useState([]);
  const [NumDocumento, setNumDocumento] = useState("");
  const [NumProcessInterno, setNumProcessInterno] = useState("");
  const [load, setLoad] = useState(false);
  const [code, setCode] = useState("");
  const [currentObject, setCurrentObject] = useState("");
  const [CodigoExterno, setCodigoExterno] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [justificativaPrioridade, setJustificativaPrioridade] = useState("");
  const [selectCategoria, setSelectCategoria] = useState({});
  const [listCategorias, setListCategorias] = useState([]);
  const [InputMaterialData, setInputMaterialData] = useState(
    initialValuesMaterial
  );
  const [InputServicosData, setInputServicosData] =
    useState(initialValueServicos);
  const [InputObrasData, setInputObrasData] = useState(initialValueObras);
  const [InputEngenhariaData, setInputEngenhariaData] = useState(
    initialValueEngenharia
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [InputImovelData, setInputImovelData] = useState(initialValueImovel);
  const { state } = useContext(AuthContext);
  const [numberRoute, setNumberRoute] = useState(0);
  const [newInput, setNewInput] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [dataEditor, setDataEditor] = useState("");
  const [contentCategory, setContentCategory] = useState("");
  const [steps, setSteps] = useState([]);
  const options = [
    { label: "Materiais", value: 1 },
    { label: "Serviços", value: 2 },
    { label: "Obras", value: 3 },
    { label: "Serviços de Engenharia", value: 4 },
    { label: "Locação Imóvel", value: 5 },
  ];
  const [Anocode, setAnoCode] = useState("");
  const schemaMaterial = yup.object().shape({
    CodigoExterno: yup.string().typeError("O Valor deve ser um número"),
    // .required("O número do Codigo Externo é obrigatório"),
    QuantidadePretendida: yup
      .number()
      .typeError("O Valor deve ser um número")
      .required("A Quantidade Pretendida  e obrigatória"),
    ValorUnitarioEstimado: yup
      .number()
      .typeError("O Valor deve ser um número")
      .required("O Valor Unitário e obrigatório"),
    // ValorTotalEstimado: yup
    //   .number()
    //   .typeError("O Valor deve ser um número")
    //   .required("O Valor Estimado e obrigatório"),
    selectPrioridade: yup
      .object()
      .typeError("A Prioridade e Obrigatória")
      .required("A Prioridade e Obrigatória"),
  });
  const schemaServico = yup.object().shape({
    CodigoExterno: yup.string().typeError("O Valor deve ser um número"),
    // .required("O número do Codigo Externo é obrigatório"),
    MesContratacao: yup
      .object()
      .typeError("O mês da Contratação e Obrigatório")
      .required("O mês da Contratação e Obrigatório"),
    ValorTotalEstimado: yup
      .number()
      .typeError("O Valor deve ser um número")
      .required("O Valor Estimado e obrigatório"),
    selectPrioridade: yup
      .object()
      .typeError("A Prioridade e Obrigatória")
      .required("A Prioridade e Obrigatória"),
  });
  const schemaImovel = yup.object().shape({
    CodigoExterno: yup.string().typeError("O Valor deve ser um número"),

    Fornecedor: yup
      .string()
      .typeError("O Fornecedor e obrigatório")
      .required("O Fornecedor e obrigatório"),
    ValorTotalEstimado: yup
      .number()
      .typeError("O Valor deve ser um número")
      .required("O Valor Estimado e obrigatório"),
    selectPrioridade: yup
      .object()
      .typeError("A Prioridade e Obrigatória")
      .required("A Prioridade e Obrigatória"),
    DataInicial: yup
      .object()
      .typeError("A Data inicial e Obrigatória")
      .required("A Data inicial e Obrigatória"),
    DataFinal: yup
      .object()
      .typeError("A Data Final e Obrigatória")
      .required("A Data Final e Obrigatória"),
  });
  const MySwal = withReactContent(Swal);
  function validaTotalCalendario(total, meses) {
    let soma = 0;
    for (let i = 0; i < meses.length; i++) {
      soma += parseInt(meses[i].value);
    }

    if (soma > parseInt(total)) {
      return false;
    } else {
      return true;
    }
  }
  async function getDataObjeto(code) {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd?codigoDfd=${code}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            const filtro = res.data.filter(
              (item) => item.Status !== "Deletado"
            );
            setListCategorias(filtro);
          }
        });
      setLoad(true);
    } catch (error) {
      ModalError(error);
    }
  }

  async function LoadData() {
    const code = searchParams.get("code");
    const ano = searchParams.get("CodigoAno");
    setAnoCode(ano);
    if (code !== null) {
      setCode(code);
      getDataObjeto(code);
      setSteps(await CheckStepsCompleted(code, state.token));
    } else {
      setLoad(true);
    }
  }
  useEffect(() => {
    LoadData();
    setSelectCategoria(options[0]);
  }, []);

  function CleanDataInput() {
    setDataEditor("");
    setJustificativaPrioridade("");
    setCodigoExterno("");
  }

  async function handleSubmit(e) {
    try {
      if (newInput) {
        switch (selectCategoria.value) {
          case options[0].value:
            const TotalMesValido = validaTotalCalendario(
              InputMaterialData.QuantidadePretendida *
                InputMaterialData.ValorUnitarioEstimado,
              InputMaterialData.meses
            );
            if (!TotalMesValido) {
              MySwal.fire({
                icon: "error",
                title: "Oops...",
                text: "A Quantidade estimada a ser contratada por mês ultrapassa a Quantidade Pretendida",
                footer: "Por favor, revise os dados ",
                backdrop: true,
                showCancelButton: false,
                confirmButtonText: "Continuar",
                showConfirmButton: true,
              });
            } else {
              let tempMaterial = { ...InputMaterialData };
              tempMaterial.Descricao = dataEditor;
              tempMaterial.justificativaPrioridade = justificativaPrioridade;
              tempMaterial.CodigoExterno = e.CodigoExterno;
              SubmitDFDMaterial(state.token, code, tempMaterial).then(
                (resp) => {
                  if (resp) {
                    setListCategorias([...listCategorias, resp]);
                    setInputMaterialData(initialValuesMaterial);
                    CleanDataInput();
                  }
                }
              );
            }

            break;

          case options[1].value:
            let tempServico = { ...InputServicosData };
            tempServico.Descricao = dataEditor;
            tempServico.justificativaPrioridade = justificativaPrioridade;
            tempServico.CodigoExterno = e.CodigoExterno;

            SubmitDFDServicos(state.token, code, tempServico).then((resp) => {
              if (resp) {
                setListCategorias([...listCategorias, resp]);
                setInputServicosData(initialValueServicos);
                CleanDataInput();
              }
            });

            break;

          case options[2].value:
            let TempObra = { ...InputObrasData };
            TempObra.Descricao = dataEditor;
            TempObra.justificativaPrioridade = justificativaPrioridade;
            TempObra.CodigoExterno = e.CodigoExterno;

            SubmitDFDObras(state.token, code, TempObra).then((resp) => {
              if (resp) {
                setListCategorias([...listCategorias, resp]);
                setInputObrasData(initialValueObras);
                CleanDataInput();
              }
            });

            break;

          case options[3].value:
            let TemEngenharia = { ...InputEngenhariaData };
            TemEngenharia.Descricao = dataEditor;
            TemEngenharia.justificativaPrioridade = justificativaPrioridade;
            TemEngenharia.CodigoExterno = e.CodigoExterno;

            SubmitDFDEngenharia(state.token, code, TemEngenharia).then(
              (resp) => {
                if (resp) {
                  setListCategorias([...listCategorias, resp]);
                  setInputEngenhariaData(initialValueEngenharia);
                  CleanDataInput();
                }
              }
            );

            break;

          case options[4].value:
            let TemImovel = { ...InputImovelData };
            TemImovel.Descricao = dataEditor;
            TemImovel.justificativaPrioridade = justificativaPrioridade;
            TemImovel.CodigoExterno = e.CodigoExterno;

            SubmitDFDImovel(state.token, code, TemImovel).then((resp) => {
              if (resp) {
                setListCategorias([...listCategorias, resp]);
                setInputImovelData(initialValueImovel);
                CleanDataInput();
              }
            });

            break;

          default:
            break;
        }
      } else {
        switch (selectCategoria.value) {
          case options[0].value:
            const TotalMesValido = validaTotalCalendario(
              InputMaterialData.QuantidadePretendida,
              InputMaterialData.meses
            );
            if (!TotalMesValido) {
              MySwal.fire({
                icon: "error",
                title: "Oops...",
                text: "A Quantidade estimada a ser contratada por mês ultrapassa a Quantidade Pretendida",
                footer: "Por favor, revise os dados ",
                backdrop: true,
                showCancelButton: false,
                confirmButtonText: "Continuar",
                showConfirmButton: true,
              });
            } else {
              let tempMaterial = { ...InputMaterialData };
              tempMaterial.Descricao = dataEditor;
              tempMaterial.justificativaPrioridade = justificativaPrioridade;
              tempMaterial.CodigoExterno = CodigoExterno;
              SubmitDFDMaterial(
                state.token,
                code,
                tempMaterial,
                currentObject
              ).then((resp) => {
                let temp = [...listCategorias];
                temp[currentIndex] = resp;

                if (temp) {
                  setListCategorias(temp);
                  setInputMaterialData(initialValuesMaterial);
                  CleanDataInput();
                }
              });
            }

            break;

          case options[1].value:
            let tempServico = { ...InputServicosData };
            tempServico.Descricao = dataEditor;
            tempServico.justificativaPrioridade = justificativaPrioridade;
            tempServico.CodigoExterno = CodigoExterno;

            SubmitDFDServicos(
              state.token,
              code,
              tempServico,
              currentObject
            ).then((resp) => {
              let temp = [...listCategorias];
              temp[currentIndex] = resp;
              if (temp) {
                setListCategorias(temp);
                setInputServicosData(initialValueServicos);
                CleanDataInput();
              }
            });

            break;

          case options[2].value:
            let TempObra = { ...InputObrasData };
            TempObra.Descricao = dataEditor;
            TempObra.justificativaPrioridade = justificativaPrioridade;
            TempObra.CodigoExterno = CodigoExterno;

            SubmitDFDObras(state.token, code, TempObra, currentObject).then(
              (resp) => {
                let temp = [...listCategorias];
                temp[currentIndex] = resp;
                if (temp) {
                  setListCategorias(temp);
                  setInputObrasData(initialValueObras);
                  CleanDataInput();
                }
              }
            );

            break;

          case options[3].value:
            let TemEngenharia = { ...InputEngenhariaData };
            TemEngenharia.Descricao = dataEditor;
            TemEngenharia.justificativaPrioridade = justificativaPrioridade;
            TemEngenharia.CodigoExterno = CodigoExterno;

            SubmitDFDEngenharia(
              state.token,
              code,
              TemEngenharia,
              currentObject
            ).then((resp) => {
              let temp = [...listCategorias];
              temp[currentIndex] = resp;
              if (temp) {
                setListCategorias(temp);
                setInputEngenhariaData(initialValueEngenharia);
                CleanDataInput();
              }
            });

            break;

          case options[4].value:
            let TemImovel = { ...InputImovelData };
            TemImovel.Descricao = dataEditor;
            TemImovel.justificativaPrioridade = justificativaPrioridade;
            TemImovel.CodigoExterno = CodigoExterno;

            SubmitDFDImovel(state.token, code, TemImovel, currentObject).then(
              (resp) => {
                let temp = [...listCategorias];
                temp[currentIndex] = resp;
                if (temp) {
                  setListCategorias(temp);
                  setInputEngenhariaData(initialValueImovel);
                  CleanDataInput();
                }
              }
            );

            break;

          default:
            break;
        }
      }
    } catch (error) {
      ModalErrorMessage(error);
    }
  }

  function handleSelectCategoria(setFieldValue) {
    switch (selectCategoria.value) {
      case options[0].value:
        return (
          <Materiais
            setFieldValue={setFieldValue}
            InputMaterialData={InputMaterialData}
            setInputMaterialData={setInputMaterialData}
            justificativaPrioridade={justificativaPrioridade}
            setJustificativaPrioridade={setJustificativaPrioridade}
          />
        );

      case options[1].value:
        return (
          <Servicos
            setFieldValue={setFieldValue}
            InputServicosData={InputServicosData}
            setInputServicosData={setInputServicosData}
            justificativaPrioridade={justificativaPrioridade}
            setJustificativaPrioridade={setJustificativaPrioridade}
          />
        );

      case options[2].value:
        return (
          <Obras
            setFieldValue={setFieldValue}
            InputObrasData={InputObrasData}
            setInputObrasData={setInputObrasData}
            justificativaPrioridade={justificativaPrioridade}
            setJustificativaPrioridade={setJustificativaPrioridade}
          />
        );

      case options[3].value:
        return (
          <ServicosEngenharia
            setFieldValue={setFieldValue}
            InputEngenhariaData={InputEngenhariaData}
            setInputEngenhariaData={setInputEngenhariaData}
            justificativaPrioridade={justificativaPrioridade}
            setJustificativaPrioridade={setJustificativaPrioridade}
          />
        );
      case options[4].value:
        return (
          <LocacaoImovel
            setFieldValue={setFieldValue}
            InputImovelData={InputImovelData}
            setInputImovelData={setInputImovelData}
            justificativaPrioridade={justificativaPrioridade}
            setJustificativaPrioridade={setJustificativaPrioridade}
          />
        );

      default:
        break;
    }
  }

  function RemoveObject(Codigo) {
    try {
      axios.delete(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd/${Codigo}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      // .then((res) => {
      //   if (res.data) {
      //     console.log(res.data);
      //     setListCategorias(res.data);
      //   }
      // });
    } catch (error) {}
    const novoArray = listCategorias.filter((item) => item.Codigo !== Codigo);
    setListCategorias(novoArray);
  }

  function EditObject(object, currentIndex) {
    setNewInput(false);
    setCurrentObject(object.Codigo);
    setCurrentIndex(currentIndex);
    switch (object?.Categoria) {
      case options[0].label:
        setSelectCategoria(options[0]);
        setDataEditor(object.Descricao);
        setCodigoExterno(object.CodigoExterno);
        setJustificativaPrioridade(object.JustificativaPrioridade);
        // FormatMaterial(object);
        setInputMaterialData(FormatMaterial(object));

        break;

      case options[1].label:
        setSelectCategoria(options[1]);

        setDataEditor(object.Descricao);
        setCodigoExterno(object.CodigoExterno);
        setJustificativaPrioridade(object.JustificativaPrioridade);
        // formatServico(object);
        setInputServicosData(formatServico(object));

        break;

      case options[2].label:
        setSelectCategoria(options[2]);

        setDataEditor(object.Descricao);
        setCodigoExterno(object.CodigoExterno);
        setJustificativaPrioridade(object.JustificativaPrioridade);
        setInputObrasData(formatObra(object));
        break;

      case options[3].label:
        setSelectCategoria(options[3]);
        setDataEditor(object.Descricao);
        setCodigoExterno(object.CodigoExterno);
        setJustificativaPrioridade(object.JustificativaPrioridade);
        setInputEngenhariaData(FormatEngenharia(object));
        break;

      case options[4].label:
        setSelectCategoria(options[4]);
        setDataEditor(object.Descricao);
        setCodigoExterno(object.CodigoExterno);
        setJustificativaPrioridade(object.JustificativaPrioridade);
        setInputImovelData(FormatImovel(object));
        break;

      default:
        break;
    }
  }
  const getValidationSchema = () => {
    if (newInput) {
      if (selectCategoria.value === 1) {
        return schemaMaterial;
      } else if (selectCategoria.value === 5) {
        return schemaImovel;
      } else {
        return schemaServico;
      }
    } else {
      return null;
    }
  };
  async function NavigateNextEtapa() {
    const routeName = RouteNamesIndex.find((item) => {
      return item.number === 5;
    });

    navigate(
      `/Cadastra-DFD?etapa=${routeName.route}&CodigoAno=${Anocode}&code=${code}`
    );
  }
  return (
    <div className="d-flex flex-row gap-2">
      <NavegacaoLateral
        ativo={routeNumber}
        age={Anocode}
        code={code}
        check={steps}
      />

      <div className="col-sm-9 mt-4">
        <div className="container">
          <h2>Especificação do Objeto</h2>
          <h6>Descrição do Objeto :</h6>
          {load ? (
            <>
              {/* {etapaIsJustify && (
                  <h4 className="btn-primary d-inline rounded-2  px-3 py-1 text-white">
                    Esta etapa foi justificada
                  </h4>
                )} */}

              {/* {(contentCategory || newInput) && ( */}
              <div className="mb-5">
                <ReactQuill
                  className="sizeEditor"
                  name="text"
                  value={dataEditor}
                  onChange={(value) => {
                    setDataEditor(value);
                  }}
                />
              </div>
              {/* )} */}

              <Formik
                initialValues={{
                  Fornecedor: "",
                  CodigoExterno: CodigoExterno,
                  ValorTotalEstimado: "",
                  ValorUnitarioEstimado: "",
                  QuantidadePretendida: "",
                  selectCategoria: selectCategoria,
                  selectPrioridade: "",
                  MesContratacao: "",
                  DataInicial: "",
                  DataFinal: "",
                }}
                validationSchema={getValidationSchema()}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form className="d-flex flex-column justify-content-end mt-3 ">
                    <div className="d-flex  ">
                      <FormGroup className="mb-3 p-1 formGroup col-1 w-50">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Categorias
                          </span>
                        </label>
                        <Select
                          name="selectCategoria"
                          options={options}
                          value={selectCategoria}
                          onChange={(selectedOptions) => {
                            setFieldValue("selectCategoria", selectedOptions);
                            setSelectCategoria(selectedOptions);
                          }}
                        />

                        <ErrorMessage
                          name="selecao"
                          component="p"
                          className="error"
                        />
                      </FormGroup>

                      <div className="d-flex flex-column w-50  ">
                        <FormGroup className="mb-3 p-1 formGroup">
                          <label>
                            <span className="d-flex flex-row align-items-center">
                              Cód. Externo
                            </span>
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="CodigoExterno"
                            value={CodigoExterno}
                            onChange={(e) => {
                              const { value } = e.target;

                              setFieldValue("CodigoExterno", value);
                              setCodigoExterno(value);
                            }}
                          />
                          <ErrorMessage component="p" name="CodigoExterno" />
                        </FormGroup>
                      </div>
                    </div>

                    {handleSelectCategoria(setFieldValue)}
                  </Form>
                )}
              </Formik>
              <table className=" especificacaoTable">
                <thead className="">
                  <tr className="">
                    <th className="">Item</th>
                    <th>Categoria</th>
                    <th>Objeto</th>
                    <th>
                      Despesa P/<br></br> Necessid. Orça.
                    </th>
                    <th>Quant. Pret.</th>
                    <th>Renov. Contrato</th>
                    <th>Total Est.</th>
                    <th>Part. Extern.</th>
                    <th>Prioridade.</th>
                    <th></th>
                  </tr>
                </thead>

                {listCategorias?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="">{index + 1}</td>
                      <td>{item?.Categoria}</td>
                      <td>{removerTags(item?.Descricao)}</td>
                      <td>{item?.DespesaInformada ? "sim" : "não"}</td>
                      <td>{item?.QuantidadePretendida}</td>
                      <td>{item?.RenovacaoContrato ? "sim" : "não"}</td>
                      <td>{item?.ValorTotalEstimado}</td>
                      <td>
                        {item?.ParticipacaoRecursosExternos ? "sim" : "não"}
                      </td>
                      <td>{item?.GrauPrioridadeContratacao}</td>
                      <td>
                        <div>
                          <HiPencil
                            type="button"
                            name="teste"
                            onClick={() => EditObject(item, index)}
                          />
                          <MdCancel
                            type="button"
                            style={{ color: "red" }}
                            onClick={() => RemoveObject(item?.Codigo)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
              <div className=" d-flex flex-row-reverse gap-3 mt-3">
                <Button
                  className="btn btn-primary button "
                  type="submit"
                  onClick={() => NavigateNextEtapa()}
                >
                  Salvar e Continuar
                </Button>
                <button
                  className=" btn btn-outline-primary button "
                  onClick={() => handleShow()}
                >
                  Visualizar
                </button>
              </div>
            </>
          ) : (
            //   <NumProcessoSkeleton />
            <></>
          )}
        </div>
        <ModalPreviewObjetos
          data={listCategorias}
          showModal={show}
          handleCloseModal={handleClose}
          title="Especificação do Objeto"
        />
        {/* <ModalPreViewEtapa
          showPreview={show}
          setShow={setShow}
          title="Justificativa da Necessidade da Contratação:"
          body={dataEditor}
        /> */}
      </div>
    </div>
  );
}
