import { Link } from "react-router-dom";

import "./MenuFull.scss";
import { RouteNamesIndex } from "../../Dictionary/RouteNamesIndex";
import { useContext, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

import AuthContext from "../../contexts/auth";

const MenuFull = (props) => {
  const { state } = useContext(AuthContext);
  function CloseMenu() {
    props.setOpenSideNavigate(false);
  }
  const [steps, setSteps] = useState([0]);

  return (
    <aside className="mt-4 aside_menu_full">
      <div className="container_menu">
        <div className="barra_superior">
          <h2>Etapas:</h2>
          <button className="btn btn-sm" onClick={CloseMenu}>
            <i className="fa-solid fa-angles-left"></i>
          </button>
        </div>
        <div className="scroll_menu_risco ">
          <nav id="nav_menu">
            <ul>
              <li className={props.ativo === 1 ? "ativo" : ""}>
                <span>{props.check?.includes(1) ? <FaCheck /> : 1} </span>
                {props.code ? (
                  <Link
                    to={`/Cadastra-DFD?etapa=${RouteNamesIndex[0].route}&CodigoAno=${props.age}&code=${props.code}`}
                  >
                    Informações básicas
                  </Link>
                ) : (
                  <Link
                    to={`/Cadastra-DFD?etapa=${RouteNamesIndex[0].route}&CodigoAno=${props.age}`}
                  >
                    Informações básicas
                  </Link>
                )}
              </li>
              <li className={props.ativo === 2 ? "ativo" : ""}>
                <span>{props.check?.includes(2) ? <FaCheck /> : 2} </span>

                <Link
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[1].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  Descrição do Objeto
                </Link>
              </li>
              <li className={props.ativo === 3 ? "ativo" : ""}>
                <span>{props.check?.includes(3) ? <FaCheck /> : 3} </span>

                <Link
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[2].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  Justificativa da necessidade
                </Link>
              </li>
              <li className={props.ativo === 4 ? "ativo" : ""}>
                <span>{props.check?.includes(4) ? <FaCheck /> : 4} </span>

                <Link
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[3].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  Especificações do Objeto
                </Link>
              </li>
              <li className={props.ativo === 5 ? "ativo" : ""}>
                <span>{props.check?.includes(5) ? <FaCheck /> : 5} </span>

                <Link
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[4].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  Finalizar
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default MenuFull;
