import styles from "./ListaDFD.scss";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useContext, useEffect, useReducer, useState } from "react";
import api from "../../../services/api";
import { HiPencil } from "react-icons/hi";
import Select from "react-select";
import { GrDocumentUser } from "react-icons/gr";

import { useNavigate } from "react-router-dom";
import { DataUserContext } from "../../../contexts/context";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers";
import AuthContext from "../../../contexts/auth";
import * as jose from "jose";
import axios from "axios";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModalAno } from "../../../components/ModalAno/ModalAno";
import dayjs from "dayjs";
import { ModalDocumento } from "../../../components/ModalDocumento/ModalDocumento";
import { ModalJustificativa } from "../../../components/ModalJustificativa/ModalJustificativa";
import { removerTags } from "../../../functions/RemoveHtml/RemoveHTML";
import { ModalError } from "../../../components/ModalError/ModalError";

const ListaDFD = () => {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const [RequestDFDs, setRequestDFDs] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [anosDFDData, setAnosDFDData] = useState([]);
  const [currentItem, setCurremtItem] = useState([]);
  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const [showDocumento, setShowDocumento] = useState(false);
  const [showModalJustificativa, setModalJustificativa] = useState(false);
  const [dataBusca, setDataBusca] = useState();

  const [statusBusca, setStatusBusca] = useState("");
  const handleCloseModalJustificativa = () => setModalJustificativa(false);
  const handleOpenModalJustificativa = () => setModalJustificativa(true);
  const handleCloseDocumento = () => setShowDocumento(false);
  const handleOpenDocumento = () => setShowDocumento(false);
  const [statusVelue, setValueStatus] = useState();
  function handleConfirmAgePCA(codigo) {
    navigate(`/Cadastra-DFD?etapa=informacoes-basicas&CodigoAno=${codigo}`);
  }
  const opitions = [
    { label: "Aguardando Aprovação Nível 01", value: 1 },
    { label: "Aguardando Aprovação Nível 02", value: 2 },
    { label: "Aprovado", value: 3 },
    { label: "Rejeitado", value: 5 },
    { label: "Em Edição", value: 6 },
    { label: "Todos", value: "" },
  ];
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleRequestBusca(event) {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASEURL
        }/api/v1.0/documentos-formalizacao-demandas?statusAprovacao=${
          statusBusca ? statusBusca?.value : ""
        }&data=${
          dataBusca ? dataBusca?.format("YYYY-MM-DDTHH:mm:ss.SSSZ") : ""
        }&objeto=${event.objetoDFD}&responsavel=${
          event.responsavel
        }&numeroDFD=${event.NumberDFD}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setRequestDFDs(response.data);
      } else {
        setRequestDFDs([]);
      }
    } catch (error) {
      ModalError(error);
    }
  }
  async function handleClick() {
    setShowEtapa(true);
  }
  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setRequestDFDs(response.data);
      }
      const responseAno = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/calendarios-pca`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (responseAno.data) {
        let formatAno = responseAno.data.map((data) => {
          return { label: `${data.Ano}`, value: data.Codigo };
        });

        setAnosDFDData(formatAno);
      }
      setErrorPage(false);
    } catch (error) {
      setErrorPage(true);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  function AtualizaListaDFD() {
    getData();
  }
  async function handleViewDFD(DFDData) {
    navigate(`/modulo-planejamento`, {
      state: {
        DFDData: DFDData,
      },
    });
  }

  function handleModalAprovacao(item) {
    setCurremtItem(item);
    setShowDocumento(true);
  }
  return (
    <>
      {!erroPage ? (
        <>
          <Navbar
            subtitulo="Módulo de Planejamento"
            titulo="Plano de Contratação Anual"
            button={{ text: "+ Cadastrar novo" }}
            onClick={handleClick}
          />

          <div className=" pb-5 d-flex flex-row justify-content-center gap-5 mt-5 ">
            <Button
              className=" customButtonPCA btn btn-warning"
              onClick={() => navigate(`/Calendario-PCA`, {})}
            >
              Calendário PCA
            </Button>
            <Button
              className=" customButtonPCA btn btn-warning"
              onClick={() =>
                navigate(`/Detalhar-PCA/${anosDFDData[0]?.label}`, {})
              }
            >
              Detalhar PCA
            </Button>
          </div>
          <main className="listagem-inicial">
            <Formik
              initialValues={{
                NumberDFD: "",
                objetoDFD: "",
                responsavel: "",
                status: "",
                unitRequester: "",
              }}
              onSubmit={handleRequestBusca}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-3   ">
                    <div className="formPCA gap-3 ">
                      <div className="col-sm">
                        <Field
                          type="text"
                          name="NumberDFD"
                          placeholder="Número do 
                        DFD"
                          className="form-control inputForm "
                        />
                      </div>
                      <div className="col-sm">
                        <DatePicker
                          format="DD/MM/YYYY"
                          className="datePiker w-100"
                          label="Data"
                          value={dataBusca}
                          onChange={(value) => {
                            setDataBusca(value);
                          }}
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          type="text"
                          name="objetoDFD"
                          placeholder="Objeto"
                          className="form-control inputForm"
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          type="text"
                          name="responsavel"
                          placeholder="Responsável"
                          className="form-control inputForm"
                        />
                      </div>
                      <div className="col-sm">
                        <Select
                          name="status"
                          value={statusBusca}
                          placeholder="Status"
                          options={opitions}
                          onChange={(selectedOptions) => {
                            // setFieldValue("status", selectedOptions);
                            setStatusBusca(selectedOptions);
                          }}
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          type="text"
                          name="unitRequester"
                          placeholder="Unidade"
                          className="form-control inputForm"
                        />
                      </div>

                      <div className="col">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container">
              <table className="table">
                <tbody>
                  <tr className={styles.linha__tabbel_listagem}>
                    <th>Número do DFD</th>
                    <th>Data do Cadastro</th>
                    <th>Objeto</th>
                    <th>Responsável</th>
                    <th>Unidade Demandante</th>
                    <th>Status</th>
                    <th></th>
                  </tr>

                  {RequestDFDs?.map((DFD) => (
                    <tr key={DFD?.Codigo}>
                      <td>{DFD?.Numero}</td>
                      <td>{dayjs(DFD?.criado).format("DD/MM/YYYY")}</td>
                      <td>{removerTags(DFD.Descricao)}</td>
                      <td>{DFD?.Responsavel?.NomeResponsavel}</td>
                      <td>{DFD?.CalendarioPca.UnidadeRequisitante}</td>
                      <td>{DFD?.StatusAprovacao}</td>
                      <td>
                        <div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                Editar DFD
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <a
                                variant="light"
                                {...triggerHandler}
                                className="d-inline-flex align-items-center p-1"
                              >
                                <i ref={ref}>
                                  <HiPencil
                                    type="button"
                                    name="teste"
                                    onClick={() =>
                                      navigate(
                                        `/Cadastra-DFD?etapa=informacoes-basicas&CodigoAno=${DFD?.CalendarioPca.Codigo}&code=${DFD.Codigo}`
                                      )
                                    }
                                  />
                                </i>
                              </a>
                            )}
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                Detalhar DFD
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) => (
                              <a
                                variant="light"
                                {...triggerHandler}
                                className="d-inline-flex align-items-center p-1"
                              >
                                <i ref={ref}>
                                  <GrDocumentUser
                                    type="button"
                                    name="teste"
                                    onClick={() => handleModalAprovacao(DFD)}
                                  />
                                </i>
                              </a>
                            )}
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ModalAno
              showModal={showEtapa}
              handleCloseModal={handleCloseEtapa}
              handleConfirm={handleConfirmAgePCA}
              AnosDFD={anosDFDData}
            />
            <ModalDocumento
              showModal={showDocumento}
              handleCloseModal={handleCloseDocumento}
              itemDFD={currentItem}
              showModalJustificativa={handleOpenModalJustificativa}
              AtualizaListaDFD={AtualizaListaDFD}
            />
            <ModalJustificativa
              showModal={showModalJustificativa}
              handleCloseModal={handleCloseModalJustificativa}
              openDocumento={handleOpenDocumento}
              itemDFD={currentItem}
              AtualizaListaDFD={AtualizaListaDFD}
            />
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ListaDFD;
