import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export function ModalPreViewEtapa({ showPreview, setShow, title, body }) {
  const handleClose = () => setShow(false);

  return (
    <Modal size="lg" show={showPreview} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Visualização</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2>{title}</h2>

        <div dangerouslySetInnerHTML={{ __html: `${body}` }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
