import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import Select from "react-select";
import CKeditorEtp from "../../../CKeditor/CKeditorEtp";
import "./Materiais.scss";
import ReactQuill from "react-quill";
import { NumericFormat } from "react-number-format";
export function Materiais({
  setFieldValue,
  InputMaterialData,
  setInputMaterialData,
  justificativaPrioridade,
  setJustificativaPrioridade,
}) {
  const [selectCategoria, setSelectCategoria] = useState({});

  const options = [
    { label: "Baixo", value: 1 },
    { label: "Médio", value: 2 },
    { label: "Alto", value: 3 },
  ];

  return (
    <>
      <div className=" d-flex flex-row w-100">
        <div className="w-100">
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup">
              <label>
                <span className="d-flex flex-row align-items-center">
                  Valor Unitário Estimado
                </span>
              </label>
              <NumericFormat
                name="ValorUnitarioEstimado"
                value={InputMaterialData.ValorUnitarioEstimado}
                onValueChange={(values) => {
                  const { value } = values;
                  const temp = { ...InputMaterialData };
                  temp.ValorUnitarioEstimado = value;
                  setFieldValue("ValorUnitarioEstimado", value);
                  setInputMaterialData(temp);
                }}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={4}
                fixedDecimalScale={false}
                prefix=""
                className="form-control"
              />
              <ErrorMessage component="p" name="ValorUnitarioEstimado" />
            </FormGroup>
          </div>

          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup col-1 w-100">
              <label className="">
                <span className="d-flex flex-row align-items-center">
                  Grau de Prioridade da Contratação
                </span>
              </label>
              <Select
                name="selectPrioridade"
                options={options}
                placeholder={"selecione a prioridade"}
                value={InputMaterialData.GrauPrioridade}
                onChange={(selectedOptions) => {
                  const temp = { ...InputMaterialData };
                  temp.GrauPrioridade = selectedOptions;

                  setFieldValue("selectPrioridade", selectedOptions);

                  setInputMaterialData(temp);
                }}
              />

              <ErrorMessage
                name="selectPrioridade"
                component="p"
                className="error"
              />
            </FormGroup>
          </div>
        </div>
        <div className="w-100">
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup">
              <label>
                <span className="d-flex flex-row align-items-center">
                  Quantidade Pretendida
                </span>
              </label>
              <Field
                value={InputMaterialData.QuantidadePretendida}
                type="text"
                className="form-control"
                name="QuantidadePretendida"
                onChange={(e) => {
                  const { value } = e.target;
                  const temp = { ...InputMaterialData };
                  temp.QuantidadePretendida = value;
                  setFieldValue("QuantidadePretendida", value);
                  setInputMaterialData(temp);
                }}
              />

              <ErrorMessage component="p" name="QuantidadePretendida" />
            </FormGroup>
          </div>
        </div>
      </div>
      {InputMaterialData.GrauPrioridade.value === 3 && (
        <>
          <h6>Justificativa da prioridade</h6>

          <div className="mb-5">
            <ReactQuill
              className="sizeEditor"
              name="text"
              value={justificativaPrioridade}
              onChange={(value) => {
                setJustificativaPrioridade(value);
              }}
            />
          </div>
        </>
      )}
      <div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked1"
            checked={InputMaterialData.TecnologiaInformacao}
            onChange={(e) => {
              const temp = { ...InputMaterialData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.TecnologiaInformacao = checked;
              setInputMaterialData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Tecnologia da Informação e Comunicação (TIC)
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked2"
            checked={InputMaterialData.RenovacaoContrato}
            onChange={(e) => {
              const temp = { ...InputMaterialData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.RenovacaoContrato = checked;
              setInputMaterialData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Renovação de Contrato
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked3"
            checked={InputMaterialData.DespesaInformada}
            onChange={(e) => {
              const temp = { ...InputMaterialData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.DespesaInformada = checked;
              setInputMaterialData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Despesa informada é somente para vincular aos
              aspectos/necessidades orçamentárias
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked4"
            checked={InputMaterialData.ParticipacaoRecursosExternos}
            onChange={(e) => {
              const temp = { ...InputMaterialData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.ParticipacaoRecursosExternos = checked;
              setInputMaterialData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Participação de Recursos Externos
            </span>
          </label>
        </div>
      </div>

      <table className=" customTable w-100 ">
        <thead className="">
          <tr className="">
            <th className="w-50 p-2">Mês</th>
            <th>Quantidade estimada a ser contratada por mês</th>
          </tr>
        </thead>
        {InputMaterialData.meses.map((mes, index) => {
          return (
            <tr key={mes.nome}>
              <th>{mes.nome}</th>
              <th>
                <Field
                  type="text"
                  className="form-control w-100"
                  name={mes.nome}
                  value={mes.value}
                  onChange={(e) => {
                    const { value } = e.target; // Obtenha o valor do evento do campo de entrada
                    const temp = { ...InputMaterialData }; // Crie uma cópia do objeto
                    temp.meses[index].value = value; // Atualize o valor no objeto temporário
                    setFieldValue("ValorUnitario", value);
                    setInputMaterialData(temp);
                  }}
                />
              </th>
            </tr>
          );
        })}
      </table>
      <div className=" d-flex flex-row-reverse gap-3 mt-3">
        <Button className="btn btn-primary button " type="submit">
          Adicionar
        </Button>
      </div>
    </>
  );
}
