import styles from "./AddCalendarPCA.scss";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useContext, useEffect, useReducer, useState } from "react";
import api from "../../../services/api";
import { HiPencil } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { DataUserContext } from "../../../contexts/context";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers";
import AuthContext from "../../../contexts/auth";
import * as jose from "jose";
import axios from "axios";
import { Button } from "react-bootstrap";

import * as yup from "yup";

import { ModalError } from "../../../components/ModalError/ModalError";
import dayjs from "dayjs";
export function AddCalendarPCA() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [CalendarData, setCalendarData] = useState([]);
  const [anoStart, setAnoStart] = useState();
  const [inicioPrevisoes, setinicioPrevisoes] = useState();
  const [fimPrevisoes, setfimPrevisoes] = useState();
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [NewInpult, setNewInpult] = useState(true);
  const [Code, setCode] = useState("");
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  const schema = yup.object().shape({
    AgeCalendar: yup.date(),
  });
  async function handleSubmitCalendar(event) {
    if (NewInpult) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/calendarios-pca`,
          {
            Ano: event.AgeCalendar,
            InicioPrevisoes: inicioPrevisoes?.format(
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            ),
            FimPrevisoes: fimPrevisoes?.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        // setErrorPage(true);
        navigate("/calendario-PCA");
      } catch (error) {
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error?.response?.data?.ErrorMessages}`,
          footer: `Erro: ${error?.response?.data?.Code} `,
          backdrop: false,
          showCancelButton: false,
          showConfirmButton: true,
        });
      }
    } else {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/calendarios-pca`,
          {
            Codigo: Code,
            Ano: event.AgeCalendar,
            InicioPrevisoes: inicioPrevisoes?.format(
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            ),
            FimPrevisoes: fimPrevisoes?.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        navigate("/calendario-PCA");
        // setErrorPage(true);
      } catch (error) {
        ModalError(error);
      }
    }
  }

  async function getData(Codigo) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/calendarios-pca/${Codigo}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setCalendarData(response.data);
        setAnoStart(`${response.data.Ano}`);
        setinicioPrevisoes(dayjs(response.data.InicioPrevisoes));
        setfimPrevisoes(dayjs(response.data.FimPrevisoes));
      }
      // setErrorPage(true);
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    const code = searchParams.get("Code");
    if (code !== null) {
      getData(code);
      setCode(code);
    } else {
      setLoad(true);
    }
  }, []);

  useEffect(() => {}, [inicioPrevisoes]);
  async function handleViewDFD(DFDData) {
    navigate(`/modulo-planejamento`, {
      state: {
        DFDData: DFDData,
      },
    });
  }
  return (
    <>
      {Load ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            titulo="Plano De Contratação Anual"
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3"> Cadastrar Calendário PCA</h3>
            <Formik
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={{
                AgeCalendar: anoStart,
              }}
              onSubmit={handleSubmitCalendar}
            >
              <Form>
                <div className="container pt-3 ">
                  <div className="formAddCalendar gap-5">
                    <div className="formGroup ">
                      <Field
                        type="text"
                        name="AgeCalendar"
                        placeholder="Ano"
                        className="form-control inputForm "
                      />
                      <ErrorMessage component="p" name="AgeCalendar" />
                    </div>

                    <div className="">
                      <DatePicker
                        format="DD/MM/YYYY"
                        className="datePiker"
                        label="Data de início das previsões"
                        value={inicioPrevisoes}
                        onChange={(newValue) => setinicioPrevisoes(newValue)}
                      />
                    </div>
                    <div className="">
                      <DatePicker
                        format="DD/MM/YYYY"
                        className="datePiker"
                        label="Data de fim das previsões"
                        value={fimPrevisoes}
                        onChange={(newValue) => setfimPrevisoes(newValue)}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row-reverse gap-3 mt-5">
                  <Button type="submit">Salvar</Button>
                  <Button onClick={() => navigate("/calendario-PCA")}>
                    Cancelar
                  </Button>
                </div>
              </Form>
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
