export function FormatMaterial(object) {
  let meses = [
    {
      nome: "Janeiro",
      value: object.QuantidadeMensalPretendida.Janeiro,
    },
    {
      nome: "Fevereiro",
      value: object.QuantidadeMensalPretendida.Fevereiro,
    },
    {
      nome: "Março",
      value: object.QuantidadeMensalPretendida.Março,
    },
    {
      nome: "Abril",
      value: object.QuantidadeMensalPretendida.Abril,
    },
    {
      nome: "Maio",
      value: object.QuantidadeMensalPretendida.Maio,
    },
    {
      nome: "Junho",
      value: object.QuantidadeMensalPretendida.Junho,
    },
    {
      nome: "Julho",
      value: object.QuantidadeMensalPretendida.Julho,
    },
    {
      nome: "Agosto",
      value: object.QuantidadeMensalPretendida.Agosto,
    },
    {
      nome: "Setembro",
      value: object.QuantidadeMensalPretendida.Setembro,
    },
    {
      nome: "Outubro",
      value: object.QuantidadeMensalPretendida.Outubro,
    },
    {
      nome: "Novembro",
      value: object.QuantidadeMensalPretendida.Novembro,
    },
    {
      nome: "Dezembro",
      value: object.QuantidadeMensalPretendida.Dezembro,
    },
  ];
  const options = [
    { label: "Baixo", value: 1 },
    { label: "Médio", value: 2 },
    { label: "Alto", value: 3 },
  ];
  const temp = options.find(
    (option) => option.label === object.GrauPrioridadeContratacao
  );

  object.GrauPrioridade = temp;
  object.meses = meses;

  return object;
}
