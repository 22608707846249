import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import Select from "react-select";
import CKeditorEtp from "../../../CKeditor/CKeditorEtp";
import ReactQuill from "react-quill";
import { DatePicker } from "@mui/x-date-pickers";
import { NumericFormat } from "react-number-format";

export function LocacaoImovel({
  setFieldValue,
  InputImovelData,
  setInputImovelData,
  justificativaPrioridade,
  setJustificativaPrioridade,
}) {
  const [selectCategoria, setSelectCategoria] = useState({});

  const [dataEditor, setDataEditor] = useState("");
  const [contentCategory, setContentCategory] = useState("");
  const options = [
    { label: "Baixo", value: 1 },
    { label: "Médio", value: 2 },
    { label: "Alto", value: 3 },
  ];
  const optionsCalendar = [
    { label: "Janeiro", value: 1 },
    { label: "Fevereiro", value: 2 },
    { label: "Março", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Maio", value: 5 },
    { label: "Junho", value: 6 },
    { label: "Julho", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Setembro", value: 9 },
    { label: "Outubro", value: 10 },
    { label: "Novembro", value: 11 },
    { label: "Dezembro", value: 12 },
  ];
  return (
    <>
      <div className=" d-flex flex-row w-100">
        <div className="w-100">
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup">
              <label>
                <span className="d-flex flex-row align-items-center">
                  Fornecedor
                </span>
              </label>
              <Field
                type="text"
                className="form-control"
                name="Fornecedor"
                value={InputImovelData.Fornecedor}
                onChange={(e) => {
                  const { value } = e.target;
                  const temp = { ...InputImovelData };
                  temp.Fornecedor = value;
                  setFieldValue("Fornecedor", value);

                  setInputImovelData(temp);
                }}
              />
              <ErrorMessage component="p" name="Fornecedor" />
            </FormGroup>
          </div>
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup w-100">
              <label>
                <span className="d-flex flex-row align-items-center">
                  Data Inicial
                </span>
              </label>

              <div className="">
                <DatePicker
                  name="DataInicial"
                  format="DD/MM/YYYY"
                  className="datePiker w-100"
                  label="Data Inicial"
                  value={InputImovelData.DataInicial}
                  onChange={(value) => {
                    const temp = { ...InputImovelData };
                    temp.DataInicial = value;
                    setFieldValue("DataInicial", value);

                    setInputImovelData(temp);
                  }}
                />
              </div>
              <ErrorMessage component="p" name="DataInicial" />
            </FormGroup>
          </div>
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup col-1 w-100">
              <label className="">
                <span className="d-flex flex-row align-items-center">
                  Grau de Prioridade da Contratação
                </span>
              </label>
              <Select
                name="selectPrioridade"
                options={options}
                placeholder={"selecione a prioridade"}
                value={InputImovelData.GrauPrioridade}
                onChange={(selectedOptions) => {
                  const temp = { ...InputImovelData };
                  temp.GrauPrioridade = selectedOptions;

                  setFieldValue("selectPrioridade", selectedOptions);

                  setInputImovelData(temp);
                }}
              />

              <ErrorMessage
                name="selectPrioridade"
                component="p"
                className="error"
              />
            </FormGroup>
          </div>
        </div>

        <div className="w-100">
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup">
              <label>
                <span className="d-flex flex-row align-items-center">
                  Valor Total Estimado
                </span>
              </label>

              <NumericFormat
                name="ValorTotalEstimado"
                value={InputImovelData.ValorTotalEstimado}
                onValueChange={(values) => {
                  const { value } = values;
                  const temp = { ...InputImovelData };
                  temp.ValorTotalEstimado = value;
                  setFieldValue("ValorTotalEstimado", value);
                  setInputImovelData(temp);
                }}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={4}
                fixedDecimalScale={true}
                prefix=""
                className="form-control"
              />
              <ErrorMessage component="p" name="ValorTotalEstimado" />
            </FormGroup>
          </div>

          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup w-100">
              <label>
                <span className="d-flex flex-row align-items-center">
                  Data Final
                </span>
              </label>

              <div className="">
                <DatePicker
                  name="DataFinal"
                  format="DD/MM/YYYY"
                  className="datePiker w-100"
                  label="Data Final"
                  value={InputImovelData.DataFinal}
                  onChange={(value) => {
                    const temp = { ...InputImovelData };
                    temp.DataFinal = value;
                    setFieldValue("DataFinal", value);

                    setInputImovelData(temp);
                  }}
                />
              </div>
              <ErrorMessage component="p" name="DataInicial" />
            </FormGroup>
          </div>
        </div>
      </div>
      {InputImovelData.GrauPrioridade.value === 3 && (
        <>
          <h6>Justificativa da prioridade</h6>

          <div className="mb-5">
            <ReactQuill
              className="sizeEditor"
              name="text"
              value={justificativaPrioridade}
              onChange={(value) => {
                setJustificativaPrioridade(value);
              }}
            />
          </div>
        </>
      )}
      <div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked1"
            checked={InputImovelData.ParticipacaoRecursosExternos}
            onChange={(e) => {
              const temp = { ...InputImovelData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.ParticipacaoRecursosExternos = checked;
              setInputImovelData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Participação de Recursos Externos
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked2"
            checked={InputImovelData.DespesaInformada}
            onChange={(e) => {
              const temp = { ...InputImovelData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.DespesaInformada = checked;
              setInputImovelData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Despesa informada é somente para vincular aos
              aspectos/necessidades orçamentárias
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked3"
            checked={InputImovelData.RenovacaoContrato}
            onChange={(e) => {
              const temp = { ...InputImovelData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.RenovacaoContrato = checked;
              setInputImovelData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Renovação de Contrato
            </span>
          </label>
        </div>

        <div className=" d-flex flex-row-reverse gap-3 mt-3">
          <Button className="btn btn-primary button " type="submit">
            Adicionar
          </Button>
        </div>
      </div>
    </>
  );
}
