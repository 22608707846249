export function FormatImovel(object) {
  const options = [
    { label: "Baixo", value: 1 },
    { label: "Médio", value: 2 },
    { label: "Alto", value: 3 },
  ];
  let temp = options.find(
    (option) => option.label === object.GrauPrioridadeContratacao
  );

  object.GrauPrioridade = temp;

  return object;
}
