import axios from "axios";
import AuthContext from "../contexts/auth";
import { ModalError } from "../components/ModalError/ModalError";

// import { useContext } from "react";

export async function CheckStepsCompleted(code, token) {
  //   const { state } = useContext(AuthContext);
  var steps = [];
  async function getDataObjeto(code) {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd?codigoDfd=${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res?.data[0]?.Descricao) {
        steps.push(4);
      }
    } catch (error) {
      ModalError(error);
    }
  }

  async function getDataRisco(code) {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas/${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res?.data?.Numero !== undefined) {
        steps.push(1);
      }
      if (res?.data?.Descricao) {
        steps.push(2);
      }
      if (res?.data?.Justificativa) {
        steps.push(3);
      }
      if (res?.data?.StatusAprovacao !== "Em Edição") {
        steps.push(5);
      }
      // getUnidadeCompradora();
    } catch (error) {}
  }
  if (code) {
    await getDataRisco(code);
    await getDataObjeto(code);
  }

  return steps;
}
