import React, { useContext } from "react";
import {
  BrowserRouter,
  Navigate,
  redirect,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import AuthContext from "../contexts/auth";
import Home from "../pages/home";

import { Auth } from "../pages/Auth";
import { CalendarPCA } from "../pages/PCA/CalendarPCA/CalendarPCA";
import ListaDFD from "../pages/PCA/ListarDFD/ListaDFD";
import { AddCalendarPCA } from "../pages/PCA/AddCalendarPCA/AddCalendarPCA";
import { AddNewDFD } from "../pages/PCA/AddNewDFD/AddNewDFD";
import { DetailPCA } from "../pages/PCA/DetailPCA/DetailPCA";

const AppRoutes = () => {
  const { state } = useContext(AuthContext);
  const Private = ({ children }) => {
    if (!state.isAuthenticated) {
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Auth />} />
        <Route
          exact
          path="/PCA"
          element={
            <Private>
              <ListaDFD />
            </Private>
          }
        />
        <Route
          exact
          path="/Novo-Calendario"
          element={
            <Private>
              <AddCalendarPCA />
            </Private>
          }
        />
        <Route
          exact
          path="/Calendario-PCA"
          element={
            <Private>
              <CalendarPCA />
            </Private>
          }
        />
        <Route
          exact
          path="/Cadastra-DFD"
          element={
            <Private>
              <AddNewDFD />
            </Private>
          }
        />
        <Route
          exact
          path="/Detalhar-PCA/:ano"
          element={
            <Private>
              <DetailPCA />
            </Private>
          }
        />

        <Route
          exact
          path="/Catalogo"
          element={
            <Private>
              <Home />
            </Private>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
