import { Button, FormGroup, Modal } from "react-bootstrap";
import "./ModalDocumento.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AuthContext from "../../contexts/auth";
import { useContext, useEffect, useState } from "react";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";

export function ModalDocumento({
  showModal,
  handleCloseModal,
  itemDFD,
  showModalJustificativa,
  AtualizaListaDFD,
}) {
  const initialValues = {
    selecao: "",
  };
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const [objetosDFD, setObjetosDFD] = useState();

  useEffect(() => {
    if (itemDFD.Codigo !== undefined) {
      GetObjetosDFDs();
    }
  }, [itemDFD]);

  async function GetObjetosDFDs() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd?codigoDfd=${itemDFD.Codigo}`,

        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        setObjetosDFD(filtro);
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  async function AproveDocumento(value) {
    try {
      const response = await axios
        .patch(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas`,
          {
            Codigo: itemDFD.Codigo,
            Aprovar: value,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(() => {
          MySwal.fire({
            icon: "success",
            title: "Sucesso",
            text: `O documento foi aprovado!`,

            backdrop: false,
            showCancelButton: false,
            showConfirmButton: true,
          });
          AtualizaListaDFD();
          handleCloseModal();
        });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response?.data.ErrorMessages};\n\n `,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function ChangerModal(option) {
    showModalJustificativa();
    handleCloseModal();
  }

  async function AbirEdicao() {
    try {
      const response = await axios
        .patch(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas/abrir-edicao/${itemDFD.Codigo}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(() => {
          MySwal.fire({
            icon: "success",
            title: "Sucesso",
            text: `O documento foi Aberto para edição`,

            backdrop: false,
            showCancelButton: false,
            showConfirmButton: true,
          });
          AtualizaListaDFD();
          handleCloseModal();
        });
    } catch (error) {}
  }
  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
      animation={false}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">
          Detalhes do documento de Formalização da Demanda
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 ">
          <div className="row w-100">
            {false ? (
              <></>
            ) : (
              <div className="">
                <div>
                  <div className="rowContainer">
                    <div className="textContainer">
                      <div>
                        <p>Status:</p>
                        {itemDFD?.StatusAprovacao}
                      </div>
                      <div>
                        <p>Unidade requisitante:</p>
                        {itemDFD?.CodigoUnidadeRequisitante}
                      </div>
                      <div>
                        <p> Número do DFD: :</p>
                        {itemDFD?.Numero}
                      </div>
                    </div>
                    <div className="textContainer">
                      <div>
                        <p>Responsável pela demanda::</p>
                        {itemDFD?.Responsavel?.NomeResponsavel}
                      </div>
                      <div>
                        <p>Matrícula:</p>
                        {itemDFD.CodigoResponsavel}
                      </div>
                    </div>
                  </div>
                  {itemDFD.JustificativaReprovacao ? (
                    <div className="rowContainer">
                      <div className="textContainer">
                        <div>
                          <p>Justificativa da Rejeição:</p>
                        </div>
                        <div>
                          <span
                            className="cssReset"
                            dangerouslySetInnerHTML={{
                              __html: `${itemDFD?.JustificativaReprovacao}`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {itemDFD.Descricao ? (
                    <div className="rowContainer">
                      <div className="textContainer">
                        <div>
                          <p>Descrição do Objeto:</p>
                        </div>
                        <div>{removerTags(itemDFD?.Descricao)}</div>{" "}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {itemDFD.Justificativa ? (
                    <div className="rowContainer">
                      <div className="textContainer">
                        <div>
                          <p>Justificativa da necessidade</p>
                        </div>
                        <div>{removerTags(itemDFD?.Justificativa)}</div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {objetosDFD?.map((item, index) => {
                    return (
                      <div key={index} className="rowContainer">
                        <div className="textContainer">
                          <div>
                            <p>Item:</p>
                            {index + 1}
                          </div>
                          <div>
                            <p>Categoria</p>
                            {item.Categoria}
                          </div>
                          <div>
                            <p> Especificação do Objeto:</p>
                            {removerTags(item.Descricao)}
                          </div>
                          <div>
                            <p> Quantidade pretendida:</p>
                            {item.QuantidadePretendida}
                          </div>
                          <div>
                            <p> Valor estimado Unitário:</p>
                            {item.ValorUnitarioEstimado}
                          </div>
                          <div>
                            <p> Valor total estimado:</p>
                            {item.ValorTotalEstimado}
                          </div>
                          <div>
                            <p> Renovação de Contrato:</p>
                            {item.RenovacaoContrato ? "sim" : "não"}
                          </div>
                          <div>
                            <p>
                              {" "}
                              Tecnologia da Informática e Comunicação (TIC):
                            </p>
                            {item.TecnologiaInformacao ? "sim" : "não"}
                          </div>
                          <div>
                            <p>
                              {" "}
                              despesa informada é somente para vincular aos
                              aspectos/necessidades orçamentárias:
                            </p>
                            {item.DespesaInformada ? "sim" : "não"}
                          </div>
                          <div>
                            <p> Prioridade:</p>
                            {item.GrauPrioridadeContratacao}
                          </div>
                          <div>
                            <p> Justificativa da Prioridade:</p>
                            {item.JustificativaPrioridade}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-end mt-5">
                  <div className=" d-flex flex-row">
                    <Button className="m-2">Gerar PDF</Button>
                    {itemDFD.StatusAprovacao === "Rejeitado" ? (
                      <Button className="m-2 " onClick={() => AbirEdicao()}>
                        Reabrir edição
                      </Button>
                    ) : (
                      <Button
                        className="m-2 "
                        onClick={() => AproveDocumento(true)}
                      >
                        Aprovar
                      </Button>
                    )}

                    {itemDFD.StatusAprovacao === "Rejeitado" ? (
                      <Button
                        className="m-2 btn_outline"
                        onClick={() => AproveDocumento(true)}
                      >
                        Reverter Julgamento
                      </Button>
                    ) : (
                      <Button
                        className="m-2 btn-Cancel"
                        onClick={() => ChangerModal(false)}
                      >
                        Rejeitar
                      </Button>
                    )}

                    <Button
                      className="m-2 btn_outline"
                      onClick={handleCloseModal}
                    >
                      Voltar
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="primary" onClick={handleJustify}>
          Voltar
        </Button>
        <Button variant="primary" onClick={handleJustify}>
          Gravar
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}
