import { useContext, useState } from "react";
import { SideNavigateContext } from "../../contexts/context.js";
import MenuFull from "../MenuFull/MenuFull.js";
import MenuRetractable from "../MenuRetractable/MenuRetractable.js";

function NavegacaoLateral(props) {
  const { OpenSideNavigate, setOpenSideNavigate } =
    useContext(SideNavigateContext);

  return (
    <>
      {OpenSideNavigate ? (
        <div className="col-sm-3">
          <MenuFull {...props} setOpenSideNavigate={setOpenSideNavigate} />
        </div>
      ) : (
        <div className="col-auto">
          <MenuRetractable
            {...props}
            setOpenSideNavigate={setOpenSideNavigate}
          />
        </div>
      )}
    </>
  );
}

export default NavegacaoLateral;
