import { Button, FormGroup, Modal } from "react-bootstrap";
import "./ModalJustificativa.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AuthContext from "../../contexts/auth";
import { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export function ModalJustificativa({
  showModal,
  handleCloseModal,
  itemDFD,
  openDocumento,
  AtualizaListaDFD,
}) {
  const [dataEditor, setDataEditor] = useState("");
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  function VoltarDocumento() {
    openDocumento();
    handleCloseModal();
  }

  async function AproveDocumento(value) {
    try {
      const response = await axios
        .patch(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas`,
          {
            Codigo: itemDFD.Codigo,
            Aprovar: value,
            JustificativaReprovacao: dataEditor,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(() => {
          AtualizaListaDFD();
        });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `${error.response?.data.ErrorMessages};\n\n `,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function SubmitRespostaDocumento() {
    AproveDocumento(false);
    openDocumento();
    handleCloseModal();
  }
  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
      animation={false}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">
          Detalhes do documento de Formalização da Demanda
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" ">
          <div className="container mt-2 mb-3">
            <h6>Justificativa da Rejeição:</h6>
          </div>
          <ReactQuill
            className="sizeEditor"
            name="text"
            value={dataEditor}
            onChange={(value) => {
              setDataEditor(value);
            }}
          />
          <div className="d-flex justify-content-end mt-5">
            <div className=" d-flex flex-row">
              <Button
                className="m-2 btn_outline"
                onClick={SubmitRespostaDocumento}
              >
                Salvar
              </Button>
              <Button className="m-2 btn_outline" onClick={VoltarDocumento}>
                Voltar
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
