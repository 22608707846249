import { useContext, useEffect, useState } from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios, { Axios } from "axios";
import AuthContext from "../../../contexts/auth";
import NavegacaoLateral from "../../NavegacaoLateral/NavegacaoLateral";
import { ModalError } from "../../ModalError/ModalError";
import { CheckStepsCompleted } from "../../../functions/CheckStepsCompleted";
import { ModalErrorMessage } from "../../ModalErrorMessage/ModalErrorMessage";

export function FinalizarEtapa({ routeNumber }) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [code, setCode] = useState("");
  const [Anocode, setAnoCode] = useState("");
  const [request, setRequest] = useState({});
  const [load, setLoad] = useState(true);
  const [steps, setSteps] = useState([]);
  const { state } = useContext(AuthContext);
  async function LoadData() {
    const code = searchParams.get("code");
    const ano = searchParams.get("CodigoAno");
    setAnoCode(ano);

    if (code !== null) {
      setCode(code);
      getData(code);
      setSteps(await CheckStepsCompleted(code, state.token));
    } else {
      // setLoad(true);
    }
  }
  useEffect(() => {
    LoadData();
  }, []);

  async function getData(codigo) {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas/${codigo}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setRequest(res.data);
          }
        });

      setLoad(true);
    } catch (error) {}
  }
  async function handleSubmit() {
    try {
      await axios
        .patch(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas/enviar-aprovacao/${code}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(() => {
          navigate("/PCA");
        });
    } catch (error) {
      ModalErrorMessage(error);
    }
  }
  return (
    <div className="d-flex flex-row gap-2">
      <NavegacaoLateral
        ativo={routeNumber}
        age={Anocode}
        code={code}
        check={steps}
      />

      {/* {!(contentCategory || newInput) ? (
              <SkeletonEtapaEditor />
            ) : ( */}
      <div className="col-sm-9 mt-4">
        {/* {etapaIsJustify && (
                  <h4 className="btn-primary d-inline rounded-2  px-3 py-1 text-white">
                    Esta etapa foi justificada
                  </h4>
                )} */}
        <div className="d-flex flex-column gap-2">
          <h2>Finalizar</h2>
        </div>
        <div>
          <div className="rowContainer">
            <div className="textContainer">
              <div>
                <p>Criado em:</p>
                {dayjs(request?.Criado).format("DD/MM/YYYY")}
              </div>
              <div>
                <p>Editado em:</p>
                {dayjs(request?.Editado).format("DD/MM/YYYY")}
              </div>

              <div>
                <p> Número do DFD: :</p>
                {request?.Numero}
              </div>
              <div>
                <p>Responsável:</p>
                {request?.Responsavel?.NomeResponsavel}
              </div>
              <div>
                <p>Área requisitante:</p>
                {/* {itemDFD.CodigoResponsavel} */}
              </div>
            </div>
          </div>
        </div>
        <div className=" d-flex flex-row-reverse gap-3 mt-3">
          <button
            className=" btn btn-primary button "
            type="submit"
            onClick={() => handleSubmit()}
          >
            Enviar Para Aprovação
          </button>
          <button className=" btn btn-outline-primary button " type="submit">
            Gerar PDF
          </button>
          <button
            className="btn btn-outline-primary button "
            onClick={() => navigate("/PCA")}
          >
            Cancelar
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}
