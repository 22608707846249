import { Modal } from "react-bootstrap";
import { removerTags } from "../../functions/RemoveHtml/RemoveHTML";

export function ModalPreviewObjetos({ data, showModal, handleCloseModal }) {
  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
      //   animation={false}
      //   backdrop="static"
      //   keyboard={false}
      //   centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">
          Detalhes do documento de Formalização da Demanda
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data?.map((item, index) => {
          return (
            <div key={index} className="rowContainer">
              <div className="textContainer">
                <div>
                  <p>Item:</p>
                  {index + 1}
                </div>
                <div>
                  <p>Categoria</p>
                  {item?.Categoria}
                </div>
                <div>
                  <p> Especificação do Objeto:</p>
                  {removerTags(item?.Descricao)}
                </div>
                <div>
                  <p> Quantidade pretendida:</p>
                  {item?.QuantidadePretendida}
                </div>
                <div>
                  <p> Valor estimado Unitário:</p>
                  {item?.ValorUnitarioEstimado}
                </div>
                <div>
                  <p> Valor total estimado:</p>
                  {item?.ValorTotalEstimado}
                </div>
                <div>
                  <p> Renovação de Contrato:</p>
                  {item?.RenovacaoContrato ? "sim" : "não"}
                </div>
                <div>
                  <p> Tecnologia da Informática e Comunicação (TIC):</p>
                  {item?.TecnologiaInformacao ? "sim" : "não"}
                </div>
                <div>
                  <p>
                    {" "}
                    despesa informada é somente para vincular aos
                    aspectos/necessidades orçamentárias:
                  </p>
                  {item?.DespesaInformada ? "sim" : "não"}
                </div>
                <div>
                  <p> Prioridade:</p>
                  {item?.GrauPrioridadeContratacao}
                </div>
                <div>
                  <p> Justificativa da Prioridade:</p>
                  {item?.JustificativaPrioridade}
                </div>
              </div>
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
}
