import "./DetailPCA.scss";

import Button from "react-bootstrap/Button";
// import Form from 'react-bootstrap/Form';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";
import { getRelativePosition } from "chart.js/helpers";
import { faker } from "@faker-js/faker";
import Navbar from "./../../../components/Navbar/Navbar";
import Select from "react-select";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";

import { useContext, useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup } from "react-bootstrap";
import * as yup from "yup";
import api from "../../../services/api";
import AuthContext from "../../../contexts/auth";
import { InformacoesBasicas } from "../../../components/PCA/InformacoesBasicas/InformacoesBasicas";
import { RouteNamesIndex } from "../../../Dictionary/RouteNamesIndex";

import { DescricaoObjeto } from "../../../components/PCA/DescricaoObjeto/DescricaoObjeto";
import { JustificativaContratacao } from "../../../components/PCA/JustificativaContratacao/JustificativaContratacao";
import { EspecificacaoObjeto } from "../../../components/PCA/EspecificacaoObjeto/EspecificacaoObjeto";
import { NavbarPlanoAnual } from "../../../components/NavbarPlanoAnual/NavbarPlanoAnual";
import { Bar } from "react-chartjs-2";
import { GoEye } from "react-icons/go";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { removerTags } from "../../../functions/RemoveHtml/RemoveHTML";
// import { NumProcessoSkeleton } from "../../../components/skeletons/NumProcessoSkeleton";

export function DetailPCA() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const labels2 = ["Nova contratação", "Renovação"];

  const labels = [
    "Material",
    "Serviço",
    "Obras",
    "Serviços de engenharia",
    "Locação Imóvel",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [0, 0, 0, 0, 0],
        borderColor: "rgba(247, 166, 37, 0.5)",
        backgroundColor: "#F7A625",
        barThickness: "flex",
      },
    ],
  };
  const data2 = {
    labels: labels2,
    datasets: [
      {
        label: "Valor Total Estimado",
        data: [0, 0],
        borderColor: "rgba(0, 96, 108, 0.5)",
        backgroundColor: "rgba(0, 96, 108, 1)",
        barThickness: "flex",
      },
    ],
  };
  const location = useLocation();
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const searchParams = new URLSearchParams(location.search);
  const [materialList, setMaterialList] = useState([]);
  const [dataGrafico, setDataGrafico] = useState(data);
  const [dataGraficoContrato, setDataGraficoContrato] = useState(data2);
  const [servicoList, setServicoList] = useState([]);
  const [requestDFDs, setRequestDFDs] = useState();
  const [obrasList, setObrasList] = useState([]);
  const [calendarios, setCalendarios] = useState();
  const [currentCalendario, setCurrentCalendario] = useState();
  const [engenhariaList, setEngenhariaList] = useState([]);
  const [imovelList, setImovelList] = useState([]);
  const [currentRoute, setCurrentRoute] = useState({});
  const [Anocode, setAnoCode] = useState("");
  const { ano } = useParams();
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };
  const optionsCategoria = [
    { label: "Materiais", value: 1 },
    { label: "Serviços", value: 2 },
    { label: "Obras", value: 3 },
    { label: "Serviços de Engenharia", value: 4 },
    { label: "Locação Imóvel", value: 5 },
  ];
  async function LoadPage(anoAtual) {
    const responseAno = await axios.get(
      `${process.env.REACT_APP_BASEURL}/api/v1.0/calendarios-pca`,
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
    );
    if (responseAno.data) {
      setCalendarios(responseAno.data);
      var temp;
      if (anoAtual === -1) {
        temp = responseAno.data[0];
        setCurrentCalendario(temp?.Codigo);
        await GetObjetosDFDs(temp?.Codigo);
      } else {
        temp = responseAno.data.filter((item) => {
          return item.Ano === parseInt(anoAtual);
        });
        if (temp.length > 0) {
          setCurrentCalendario(temp[0]?.Codigo);
          await GetObjetosDFDs(temp[0]?.Codigo);
        }
      }

      // let formatAno = responseAno.data.map((data) => {
      //   return { label: `${data.Ano}`, value: data.Codigo };
      // });
      // console.log(formatAno);
      // setAnosDFDData(formatAno);
    }
  }
  useEffect(() => {
    LoadPage(ano);
  }, [ano]);

  async function LoadData() {
    setAnoCode(ano);

    if (ano !== null) {
      LoadPage(ano);
    } else {
      LoadPage(-1);
    }
  }
  useEffect(() => {
    LoadData();
  }, []);

  async function GetObjetosDFDs(codigoCalendario, values = "") {
    let categoria = "";
    let descricao = "";
    if (values?.categoria?.value) {
      categoria = values?.categoria?.value;
    }
    if (values?.objeto) {
      descricao = values?.objeto;
    }

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd/${codigoCalendario}?categoria=${categoria}&descricaoObjeto=${descricao}`,

        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (data) {
        setRequestDFDs(data);
        setMaterialList(data.Materiais);
        setServicoList(data.Servicos);
        setObrasList(data.Obras);
        setEngenhariaList(data.ServicosEngenharia);
        setImovelList(data.LocacaoImoveis);
        SumContratos(
          data.Materiais,
          data.Servicos,
          data.Obras,
          data.ServicosEngenharia,
          data.LocacaoImoveis,
          data.ValorTotalEstimado
        );
        setDataGrafico({
          labels,
          datasets: [
            {
              label: "Valor Total Estimado",
              data: [
                data.ValorTotalEstimadoMateriais,
                data.ValorTotalEstimadoServicos,
                data.ValorTotalEstimadoObras,
                data.ValorTotalEstimadoServicosEngenharia,
                data.ValorTotalEstimadoLocacaoImovel,
              ],
              borderColor: "rgba(247, 166, 37, 0.5)",
              backgroundColor: "#F7A625",
              barThickness: "flex",
            },
          ],
        });
      } else {
        setRequestDFDs([]);
        setMaterialList([]);
        setServicoList([]);
        setObrasList([]);
        setEngenhariaList([]);
        setImovelList([]);
        setDataGrafico(data);
        setDataGraficoContrato(data2);
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  function SumContratos(material, servico, obra, engenharia, imovel, total) {
    var sumRenova = 0;
    let contratoMaterial = material?.filter(
      (item) => item.RenovacaoContrato === true
    );
    let contratoServico = servico?.filter(
      (item) => item.RenovacaoContrato === true
    );
    let contratoObra = obra?.filter((item) => item.RenovacaoContrato === true);
    let contratoEngenharia = engenharia?.filter(
      (item) => item.RenovacaoContrato === true
    );
    let contratoImovel = imovel?.filter(
      (item) => item.RenovacaoContrato === true
    );
    contratoMaterial?.map((item) => (sumRenova += item.ValorTotalEstimado));
    contratoServico?.map((item) => (sumRenova += item.ValorTotalEstimado));
    contratoObra?.map((item) => (sumRenova += item.ValorTotalEstimado));
    contratoEngenharia?.map((item) => (sumRenova += item.ValorTotalEstimado));
    contratoImovel?.map((item) => (sumRenova += item.ValorTotalEstimado));

    setDataGraficoContrato({
      labels: labels2,
      datasets: [
        {
          label: "Valor Total Estimado",
          data: [total - sumRenova, sumRenova],
          borderColor: "rgba(0, 96, 108, 0.5)",
          backgroundColor: "rgba(0, 96, 108, 1)",
          barThickness: "flex",
        },
      ],
    });
  }

  return (
    <>
      <Navbar
        titulo="Plano De Contratação Anual"
        link="/PCA"
        NomeLink="Plano de Contratação Anual"
      />

      <main>
        <div className="">
          <div className="d-flex flex-row gap-3 ">
            <NavbarPlanoAnual ano={ano} data={calendarios} />
            <div className=" d-flex flex-column  w-100">
              <Formik
                initialValues={{
                  Unidade: "",
                  categoria: "",
                  objeto: "",
                }}
                onSubmit={(values) => GetObjetosDFDs(currentCalendario, values)}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className=" pt-3 ">
                      <div className="formAddCalendar gap-5">
                        <div className=" ">
                          <Field
                            type="text"
                            name="Unidade"
                            placeholder="Unidade Requisitante"
                            className="form-control inputForm "
                          />
                        </div>

                        <Select
                          className=" w-25 selectDetailPCA "
                          name="categoria"
                          options={optionsCategoria}
                          placeholder="Categoria"
                          value={values.categoria}
                          onChange={(selectedOptions) => {
                            setFieldValue("categoria", selectedOptions);
                          }}
                        />

                        <div className=" ">
                          <Field
                            type="text"
                            name="objeto"
                            placeholder="Objeto"
                            className="form-control inputForm "
                          />
                        </div>

                        <div className="col">
                          <button className="btn btn-primary " type="submit">
                            Pesquisar
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="d-flex flex-row  justify-content-center pt-4 w-100 g-5">
                <div className="chartContainer">
                  <div className=" valuesTitleComponent  pb-3 d-flex flex-column justify-content-center align-content-center  align-items-center">
                    <h6>Quantidade de Itens propostos</h6>
                    <p className="fs-1">
                      {requestDFDs?.QuantidadeTotalEstimada}
                    </p>
                    <h6>Valor total estimado</h6>
                    <p className="fs-4">R$ {requestDFDs?.ValorTotalEstimado}</p>
                  </div>

                  <Bar options={options} data={dataGraficoContrato} />
                </div>
                <div className="chartContainer2">
                  <Bar options={options} data={dataGrafico} />
                </div>
              </div>
              {materialList?.length !== 0 && (
                <div className="w-100">
                  <h4 className="titleTableDetail">Materiais</h4>
                  <table className=" DetailTable w-100 ">
                    <thead className="w-100">
                      <tr className="w-100">
                        <th className="">Número do DFD</th>
                        <th>Unidade demandante</th>
                        <th>Objeto</th>
                        <th>Despesa somente p/ necessidades orçamentárias</th>
                        <th>Renovação de contrato</th>
                        <th>Particip. de Recursos externo</th>
                        <th>Valor total estimado.</th>
                        <th></th>
                      </tr>
                    </thead>
                    {materialList.map((item, index) => {
                      return (
                        <tr key={item.Codigo}>
                          <td className="">{index + 1}</td>
                          <td>
                            {
                              item.DocumentoFormalizacaoDemanda?.CalendarioPca
                                ?.Comprador.NomeComprador
                            }
                          </td>
                          <td>{removerTags(item.Descricao)}</td>
                          <td>{item.DespesaInformada ? "sim" : "não"}</td>
                          <td>{item.RenovacaoContrato ? "sim" : "não"}</td>
                          <td>
                            {item.ParticipacaoRecursosExternos ? "sim" : "não"}
                          </td>
                          <td>{item.ValorTotalEstimado}</td>

                          <td>
                            <div>
                              <GoEye
                                type="button"
                                name="teste"
                                onClick={() => {}}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <div className="TotalValueTable ">
                    <div className="">Total</div>
                    <div className="">
                      {" "}
                      R$ {requestDFDs.ValorTotalEstimadoMateriais}
                    </div>
                  </div>
                  {/* <div className="PageTableDetail">
                  <div>
                    <div>Exibindo: 5 de 20</div>
                    <div>aaa</div>
                  </div>
                </div> */}
                </div>
              )}
              {servicoList?.length !== 0 && (
                <div className="w-100">
                  <h4 className="titleTableDetail">Serviços</h4>
                  <table className=" DetailTable w-100 ">
                    <thead className="w-100">
                      <tr className="w-100">
                        <th className="">Número do DFD</th>
                        <th>Unidade demandante</th>
                        <th>Objeto</th>
                        <th>Despesa somente p/ necessidades orçamentárias</th>
                        <th>Renovação de contrato</th>
                        <th>Particip. de Recursos externo</th>
                        <th>Valor total estimado.</th>
                        <th></th>
                      </tr>
                    </thead>
                    {servicoList.map((item, index) => {
                      return (
                        <tr key={item.Codigo}>
                          <td className="">{index + 1}</td>
                          <td>
                            {
                              item.DocumentoFormalizacaoDemanda?.CalendarioPca
                                ?.Comprador.NomeComprador
                            }
                          </td>
                          <td>{removerTags(item.Descricao)}</td>
                          <td>{item.DespesaInformada ? "sim" : "não"}</td>
                          <td>{item.RenovacaoContrato ? "sim" : "não"}</td>
                          <td>
                            {item.ParticipacaoRecursosExternos ? "sim" : "não"}
                          </td>
                          <td>{item.ValorTotalEstimado}</td>

                          <td>
                            <div>
                              <GoEye
                                type="button"
                                name="teste"
                                onClick={() => {}}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <div className="TotalValueTable ">
                    <div className="">Total</div>
                    <div className="">
                      {" "}
                      R$ {requestDFDs.ValorTotalEstimadoServicos}
                    </div>
                  </div>
                  {/* <div className="PageTableDetail">
                  <div>
                    <div>Exibindo: 5 de 20</div>
                    <div>aaa</div>
                  </div>
                </div> */}
                </div>
              )}
              {obrasList?.length !== 0 && (
                <div className="w-100">
                  <h4 className="titleTableDetail">Obras</h4>
                  <table className=" DetailTable w-100 ">
                    <thead className="w-100">
                      <tr className="w-100">
                        <th className="">Número do DFD</th>
                        <th>Unidade demandante</th>
                        <th>Objeto</th>
                        <th>Despesa somente p/ necessidades orçamentárias</th>
                        <th>Renovação de contrato</th>
                        <th>Particip. de Recursos externo</th>
                        <th>Valor total estimado.</th>
                        <th></th>
                      </tr>
                    </thead>
                    {obrasList.map((item, index) => {
                      return (
                        <tr key={item.Codigo}>
                          <td className="">{index + 1}</td>
                          <td>
                            {
                              item.DocumentoFormalizacaoDemanda?.CalendarioPca
                                ?.Comprador.NomeComprador
                            }
                          </td>
                          <td>{removerTags(item.Descricao)}</td>
                          <td>{item.DespesaInformada ? "sim" : "não"}</td>
                          <td>{item.RenovacaoContrato ? "sim" : "não"}</td>
                          <td>
                            {item.ParticipacaoRecursosExternos ? "sim" : "não"}
                          </td>
                          <td>{item.ValorTotalEstimado}</td>

                          <td>
                            <div>
                              <GoEye
                                type="button"
                                name="teste"
                                onClick={() => {}}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <div className="TotalValueTable ">
                    <div className="">Total</div>
                    <div className="">
                      R$ {requestDFDs.ValorTotalEstimadoObras}
                    </div>
                  </div>
                  {/* <div className="PageTableDetail">
                  <div>
                    <div>Exibindo: 5 de 20</div>
                    <div>aaa</div>
                  </div>
                </div> */}
                </div>
              )}
              {engenhariaList?.length !== 0 && (
                <div className="w-100">
                  <h4 className="titleTableDetail">Serviços de Engenharia</h4>
                  <table className=" DetailTable w-100 ">
                    <thead className="w-100">
                      <tr className="w-100">
                        <th className="">Número do DFD</th>
                        <th>Unidade demandante</th>
                        <th>Objeto</th>
                        <th>Despesa somente p/ necessidades orçamentárias</th>
                        <th>Renovação de contrato</th>
                        <th>Particip. de Recursos externo</th>
                        <th>Valor total estimado.</th>
                        <th></th>
                      </tr>
                    </thead>
                    {engenhariaList.map((item, index) => {
                      return (
                        <tr key={item.Codigo}>
                          <td className="">{index + 1}</td>
                          <td>
                            {
                              item.DocumentoFormalizacaoDemanda?.CalendarioPca
                                ?.Comprador.NomeComprador
                            }
                          </td>
                          <td>{removerTags(item.Descricao)}</td>
                          <td>{item.DespesaInformada ? "sim" : "não"}</td>
                          <td>{item.RenovacaoContrato ? "sim" : "não"}</td>
                          <td>
                            {item.ParticipacaoRecursosExternos ? "sim" : "não"}
                          </td>
                          <td>{item.ValorTotalEstimado}</td>

                          <td>
                            <div>
                              <GoEye
                                type="button"
                                name="teste"
                                onClick={() => {}}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <div className="TotalValueTable ">
                    <div className="">Total</div>
                    <div className="">
                      R${requestDFDs.ValorTotalEstimadoServicosEngenharia}
                    </div>
                  </div>
                  {/* <div className="PageTableDetail">
                  <div>
                    <div>Exibindo: 5 de 20</div>
                    <div>aaa</div>
                  </div>
                </div> */}
                </div>
              )}
              {imovelList?.length !== 0 && (
                <div className="w-100">
                  <h4 className="titleTableDetail">Locação Imóvel</h4>
                  <table className=" DetailTable w-100 ">
                    <thead className="w-100">
                      <tr className="w-100">
                        <th className="">Número do DFD</th>
                        <th>Unidade demandante</th>
                        <th>Objeto</th>
                        <th>Despesa somente p/ necessidades orçamentárias</th>
                        <th>Renovação de contrato</th>
                        <th>Particip. de Recursos externo</th>
                        <th>Valor total estimado.</th>
                        <th></th>
                      </tr>
                    </thead>
                    {imovelList.map((item, index) => {
                      return (
                        <tr key={item.Codigo}>
                          <td className="">{index + 1}</td>
                          <td>
                            {
                              item.DocumentoFormalizacaoDemanda?.CalendarioPca
                                ?.Comprador.NomeComprador
                            }
                          </td>
                          <td>{removerTags(item.Descricao)}</td>
                          <td>{item.DespesaInformada ? "sim" : "não"}</td>
                          <td>{item.RenovacaoContrato ? "sim" : "não"}</td>
                          <td>
                            {item.ParticipacaoRecursosExternos ? "sim" : "não"}
                          </td>
                          <td>{item.ValorTotalEstimado}</td>

                          <td>
                            <div>
                              <GoEye
                                type="button"
                                name="teste"
                                onClick={() => {}}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  <div className="TotalValueTable ">
                    <div className="">Total</div>
                    <div className="">
                      R$ {requestDFDs.ValorTotalEstimadoLocacaoImovel}
                    </div>
                  </div>
                  {/* <div className="PageTableDetail">
                  <div>
                    <div>Exibindo: 5 de 20</div>
                    <div>aaa</div>
                  </div>
                </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      {/* Rodape */}
      <Footer />
    </>
  );
}
