import { Link } from "react-router-dom";

import "./NavbarPlanoAnual.scss";
import { RouteNamesIndex } from "../../Dictionary/RouteNamesIndex";
import { useContext, useEffect, useState } from "react";
import { SideNavigateContext } from "../../contexts/context";

export function NavbarPlanoAnual(props) {
  const [OpenSideNavigate, setOpenSideNavigate] = useState(true);
  function CloseMenu() {
    setOpenSideNavigate(!OpenSideNavigate);
  }
  return (
    <>
      {OpenSideNavigate ? (
        <aside className="mt-4 aside_menu_full_plano_anual">
          <div className="container_menu_Plano_Anual">
            <div className="barra_superior">
              <h2 className="">Planos anuais</h2>
              <button className="btn btn-sm" onClick={CloseMenu}>
                <i className="fa-solid fa-angles-left "></i>
              </button>
            </div>
            <div className="scroll_menu_risco ">
              <nav>
                <ul>
                  {props.data?.map((item, index) => {
                    return (
                      <li
                        className={
                          item.Ano == props.ano ? "ativo_Plano_Anual" : ""
                        }
                      >
                        <Link
                          className="linkNav"
                          to={`/Detalhar-PCA/${item.Ano}`}
                        >
                          Plano anual - {item.Ano}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </aside>
      ) : (
        <aside className="mt-4 aside_retractable_Plano_Anual">
          <div className="menu_retractable">
            <div className="barra_superior_retractable">
              <button className="btn btn-sm" onClick={CloseMenu}>
                <i className="fa-solid fa-angles-right"></i>
              </button>
            </div>
            <div className="scroll_menu_risco ">
              <nav>
                <ul>
                  {props.data?.map((item, index) => {
                    return (
                      <li
                        className={
                          item.Ano == props.ano ? "ativo_Plano_Anual" : ""
                        }
                      >
                        <Link
                          className="linkNav"
                          to={`/Detalhar-PCA/${item.Ano}`}
                        >
                          {item.Ano}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </aside>
      )}
    </>
  );
}
