import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Link, useLocation, useNavigate } from "react-router-dom";

import CKeditorEtp from "./../../../components/CKeditor/CKeditorEtp";
import Navbar from "../../Navbar/Navbar";
import NavegacaoLateral from "../../NavegacaoLateral/NavegacaoLateral";
import Footer from "../../Footer/Footer";
import AuthContext from "../../../contexts/auth";
import { RouteNamesIndex } from "../../../Dictionary/RouteNamesIndex";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ModalPreViewEtapa } from "../../ModalPreViewEtapa/ModalPreViewEtapa";
import { CheckStepsCompleted } from "../../../functions/CheckStepsCompleted";
import { ModalErrorMessage } from "../../ModalErrorMessage/ModalErrorMessage";
import { ModalError } from "../../ModalError/ModalError";
// import { GroupButtons } from "../../../components/GroupButons/GroupButtons";
// import { ModalJustify } from "../../../components/ModalJustify/ModalJustify";

// import { SkeletonEtapaEditor } from "../../../components/Skeletons/SkeletonEtapaEditor";

export function DescricaoObjeto({ routeNumber }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEtapa, setShowEtapa] = useState(false);
  const handleCloseEtapa = () => setShowEtapa(false);
  const handleShowEtapa = () => {
    if (!etapaIsJustify) {
      setShowEtapa(true);
    } else {
      setEtapaIsJustify(false);
    }
  };
  const [dataEditor, setDataEditor] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [request, setRequest] = useState({});
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const [etapaIsJustify, setEtapaIsJustify] = useState(false);
  const [load, setLoad] = useState(true);
  const [newInput, setNewInput] = useState(false);
  const [code, setCode] = useState("");
  const [Anocode, setAnoCode] = useState("");
  const { state } = useContext(AuthContext);
  const [steps, setSteps] = useState([]);
  async function LoadData() {
    const code = searchParams.get("code");
    const ano = searchParams.get("CodigoAno");
    setAnoCode(ano);

    if (code !== null) {
      setCode(code);
      getDataDescricao(code);
      setSteps(await CheckStepsCompleted(code, state.token));
    } else {
      // setLoad(true);
    }
  }
  useEffect(() => {
    LoadData();
  }, []);

  async function getDataDescricao(codigo) {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas/${codigo}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          setRequest(res.data);
          setDataEditor(res.data.Descricao);
        });

      setLoad(true);
    } catch (error) {
      ModalError(error);
    }
  }
  useEffect(() => {
    setNewInput(true);
  }, [dataEditor]);
  async function submitDescription() {
    try {
      const routeName = RouteNamesIndex.find((item) => {
        return item.number === 3;
      });
      let tempData = { ...request };
      tempData.Descricao = dataEditor;

      await axios
        .put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas`,

          tempData,

          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(() => {
          navigate(
            `/Cadastra-DFD?etapa=${routeName.route}&CodigoAno=${Anocode}&code=${code}`
          );
        });
    } catch (error) {
      ModalErrorMessage(error);
    }
  }

  return (
    <>
      <div className="d-flex flex-row gap-2">
        <NavegacaoLateral
          ativo={routeNumber}
          age={Anocode}
          code={code}
          check={steps}
        />

        {!load ? (
          // <SkeletonEtapaEditor />
          <></>
        ) : (
          <div className="col-sm-9 mt-4">
            {/* {etapaIsJustify && (
                  <h4 className="btn-primary d-inline rounded-2  px-3 py-1 text-white">
                    Esta etapa foi justificada
                  </h4>
                )} */}
            <div className="d-flex flex-column gap-2 ">
              <h2>Descrição do Objeto</h2>
              <h6>Descreva o Objeto de Forma Sucinta:</h6>
            </div>
            <div className="h-50 mb-5">
              <ReactQuill
                className="h-100"
                name="text"
                value={dataEditor}
                onChange={(value) => {
                  setDataEditor(value);
                }}
              />
            </div>

            <div className=" d-flex flex-row-reverse gap-3 mt-3">
              <Button
                className="btn btn-primary button "
                onClick={() => submitDescription()}
              >
                Salvar e Continuar
              </Button>
              <button
                className=" btn btn-outline-primary button "
                onClick={() => handleShow()}
              >
                Visualizar
              </button>
            </div>
            <ModalPreViewEtapa
              showPreview={show}
              setShow={setShow}
              title="Descrição do Objeto:"
              body={dataEditor}
            />
          </div>
        )}
      </div>
    </>
  );
}
