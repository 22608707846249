import axios from "axios";
import { ModalErrorMessage } from "../components/ModalErrorMessage/ModalErrorMessage";

export default async function SubmitDFDMaterial(
  token,
  codigoDFD,
  data,
  codigoObjeto = ""
) {
  const options = [
    { label: "Materiais", value: 1 },
    { label: "Serviços", value: 2 },
    { label: "Obras", value: 3 },
    { label: "Serviços de Engenharia", value: 4 },
    { label: "Locação Imóvel", value: 5 },
  ];
  const optionsPrioridade = [
    { label: "Baixo", value: 1 },
    { label: "Médio", value: 2 },
    { label: "Alto", value: 3 },
  ];

  const dataMaterial = {
    QuantidadeMensalPretendida: {
      "01": data.meses[0].value,
      "02": data.meses[1].value,
      "03": data.meses[2].value,
      "04": data.meses[3].value,
      "05": data.meses[4].value,
      "06": data.meses[5].value,
      "07": data.meses[6].value,
      "08": data.meses[7].value,
      "09": data.meses[8].value,
      10: data.meses[9].value,
      11: data.meses[10].value,
      12: data.meses[11].value,
    },
    Descricao: data.Descricao,
    Categoria: 1,
    CodigoExterno: data.CodigoExterno,
    QuantidadePretendida: data.QuantidadePretendida,
    GrauPrioridadeContratacao: data.GrauPrioridade.value,
    ValorTotalEstimado: data.QuantidadePretendida * data.ValorUnitarioEstimado,
    JustificativaPrioridade: data.justificativaPrioridade,
    RenovacaoContrato: data.RenovacaoContrato,
    DespesaInformada: data.DespesaInformada,
    ParticipacaoRecursosExternos: data.ParticipacaoRecursosExternos,
    ValorUnitarioEstimado: data.ValorUnitarioEstimado,
    TecnologiaInformacaoComunicacao: data.TecnologiaInformacao,
    CodigoDfd: codigoDFD,
    Codigo: codigoObjeto,
  };
  try {
    let resp = "";
    if (!codigoObjeto) {
      resp = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd`,
        dataMaterial,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      await axios.put(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/objetos-dfd`,
        dataMaterial,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dataMaterial.Categoria = options[dataMaterial.Categoria - 1].label;
      dataMaterial.GrauPrioridadeContratacao =
        optionsPrioridade[dataMaterial.GrauPrioridadeContratacao - 1].label;

      return dataMaterial;
    }

    return resp.data;
  } catch (error) {
    ModalErrorMessage(error);
  }
}
