import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function ModalErrorMessage(message) {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    icon: "error",
    title: "Oops...",
    text: `${message.response?.data.ErrorMessages};\n\n `,
    footer: `Erro: ${message.response?.status} `,
    backdrop: false,
    showCancelButton: false,
    showConfirmButton: true,
  });

  return <></>;
}
