import { useEffect, useReducer, useState } from "react";
import AuthContext from "./contexts/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import authReducer, { authReducerInitialValue } from "./reducers/auth";
import { ReactSession } from "react-client-session";
import "./App.css";
import {
  SideNavigateContext,
  DataUserContext,
  CategoriesContext,
} from "./contexts/context";
import AppRoutes from "./routes/Routes";
import WithAxios from "./services/WhithAxios";
import { Decode } from "./services/Decode";
import { ptBR } from "@mui/x-date-pickers/locales";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR as dataGridPtBR } from "@mui/x-data-grid";
import { ptBR as corePtBR } from "@mui/material/locale";
function App() {
  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    ptBR, // use 'de' locale for UI texts (start, next month, ...)
    dataGridPtBR,
    corePtBR
  );
  const [state, dispatch] = useReducer(authReducer, authReducerInitialValue());
  const [userData, setUserData] = useState({});
  const [OpenSideNavigate, setOpenSideNavigate] = useState(true);
  const [categories, setCategories] = useState({});
  ReactSession.setStoreType("sessionStorage");
  async function loadStorage() {
    const token = ReactSession.get("token");

    if (!!token) {
      dispatch({
        type: "LOADED",
        payload: token,
      });
    }
  }
  useEffect(() => {
    loadStorage();
  }, [state.isAuthenticated]);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthContext.Provider value={{ state, dispatch }}>
          <WithAxios>
            <CategoriesContext.Provider value={{ categories, setCategories }}>
              <DataUserContext.Provider value={{ userData, setUserData }}>
                <SideNavigateContext.Provider
                  value={{ OpenSideNavigate, setOpenSideNavigate }}
                >
                  <AppRoutes />
                </SideNavigateContext.Provider>
              </DataUserContext.Provider>
            </CategoriesContext.Provider>
          </WithAxios>
        </AuthContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
