import { Button, FormGroup, Modal } from "react-bootstrap";
import "./ModalPCA.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";

export function ModalAno({
  showModal,
  handleCloseModal,
  handleConfirm,
  AnosDFD,
}) {
  const initialValues = {
    selecao: "",
  };

  const handleSubmit = (values) => {
    // Faça algo com os valores do formulário aqui
    handleConfirm(values.selecao);
  };
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      animation={false}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="ModalTitle">
        <Modal.Title className="text-light">Ano do PCA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form>
                <div className="d-flex align-items-center flex-column pb-5 ">
                  <label htmlFor="selecao">
                    Selecione o ano de inclusão do DFD no PCA:
                  </label>
                  <Field as="select" name="selecao">
                    <option value="" label="Selecione uma opção" />
                    {AnosDFD.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="selecao"
                    component="div"
                    className="error"
                  />
                </div>
                <div className=" mt-5 gap-4 d-flex flex-row-reverse">
                  <Button variant="primary" onClick={handleSubmit}>
                    Gravar
                  </Button>
                  <Button variant="primary" onClick={handleCloseModal}>
                    Voltar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="primary" onClick={handleJustify}>
          Voltar
        </Button>
        <Button variant="primary" onClick={handleJustify}>
          Gravar
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}
