import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import Select from "react-select";
import CKeditorEtp from "../../../CKeditor/CKeditorEtp";
import ReactQuill from "react-quill";
import { NumericFormat } from "react-number-format";

export function ServicosEngenharia({
  setFieldValue,
  InputEngenhariaData,
  setInputEngenhariaData,
  justificativaPrioridade,
  setJustificativaPrioridade,
}) {
  const [selectCategoria, setSelectCategoria] = useState({});

  const [dataEditor, setDataEditor] = useState("");
  const [contentCategory, setContentCategory] = useState("");
  const options = [
    { label: "Baixo", value: 1 },
    { label: "Médio", value: 2 },
    { label: "Alto", value: 3 },
  ];
  const optionsCalendar = [
    { label: "Janeiro", value: 1 },
    { label: "Fevereiro", value: 2 },
    { label: "Março", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Maio", value: 5 },
    { label: "Junho", value: 6 },
    { label: "Julho", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Setembro", value: 9 },
    { label: "Outubro", value: 10 },
    { label: "Novembro", value: 11 },
    { label: "Dezembro", value: 12 },
  ];
  return (
    <>
      <div className=" d-flex flex-row w-100">
        <div className="w-100">
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup">
              <label>
                <span className="d-flex flex-row align-items-center">
                  Valor Total Estimado
                </span>
              </label>
              <NumericFormat
                name="ValorTotalEstimado"
                value={InputEngenhariaData.ValorTotalEstimado}
                onValueChange={(values) => {
                  const { value } = values;
                  const temp = { ...InputEngenhariaData };
                  temp.ValorTotalEstimado = value;
                  setFieldValue("ValorTotalEstimado", value);
                  setInputEngenhariaData(temp);
                }}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={4}
                fixedDecimalScale={true}
                prefix=""
                className="form-control"
              />

              <ErrorMessage component="p" name="ValorTotalEstimado" />
            </FormGroup>
          </div>

          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup col-1 w-100">
              <label className="">
                <span className="d-flex flex-row align-items-center">
                  Grau de Prioridade da Contratação
                </span>
              </label>
              <Select
                name="selectPrioridade"
                placeholder={"selecione a prioridade"}
                options={options}
                value={InputEngenhariaData.GrauPrioridade}
                onChange={(selectedOptions) => {
                  const temp = { ...InputEngenhariaData };
                  temp.GrauPrioridade = selectedOptions;

                  setFieldValue("selectPrioridade", selectedOptions);

                  setInputEngenhariaData(temp);
                }}
              />

              <ErrorMessage
                name="selectPrioridade"
                component="p"
                className="error"
              />
            </FormGroup>
          </div>
        </div>
        <div className="w-100">
          <div className="d-flex flex-column w-100  ">
            <FormGroup className="mb-3 p-1 formGroup col-1 w-100">
              <label className="">
                <span className="d-flex flex-row align-items-center">
                  Mês de Inicio
                </span>
              </label>
              <Select
                name="MesContratacao"
                options={optionsCalendar}
                placeholder={"selecione o mês"}
                value={InputEngenhariaData.MesInicio}
                onChange={(selectedOptions) => {
                  const temp = { ...InputEngenhariaData };
                  temp.MesInicio = selectedOptions;
                  setInputEngenhariaData(temp);
                  setFieldValue("MesContratacao", selectedOptions);
                }}
              />

              <ErrorMessage
                name="MesContratacao"
                component="p"
                className="error"
              />
            </FormGroup>
          </div>
        </div>
      </div>
      {InputEngenhariaData.GrauPrioridade.value === 3 && (
        <>
          <h6>Justificativa da prioridade</h6>

          <div className="mb-5">
            <ReactQuill
              className="sizeEditor"
              name="text"
              value={justificativaPrioridade}
              onChange={(value) => {
                setJustificativaPrioridade(value);
              }}
            />
          </div>
        </>
      )}
      <div>
        <div className="d-flex flex-row gap-2">
          <Field
            className=""
            type="checkbox"
            name="checked1"
            checked={InputEngenhariaData.TecnologiaInformacao}
            onChange={(e) => {
              const temp = { ...InputEngenhariaData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.TecnologiaInformacao = checked;
              setInputEngenhariaData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Tecnologia da Informação e Comunicação (TIC)
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked2"
            checked={InputEngenhariaData.RenovacaoContrato}
            onChange={(e) => {
              const temp = { ...InputEngenhariaData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.RenovacaoContrato = checked;
              setInputEngenhariaData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Renovação de Contrato
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked3"
            checked={InputEngenhariaData.DespesaInformada}
            onChange={(e) => {
              const temp = { ...InputEngenhariaData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.DespesaInformada = checked;
              setInputEngenhariaData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Despesa informada é somente para vincular aos
              aspectos/necessidades orçamentárias
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked4"
            checked={InputEngenhariaData.ParticipacaoRecursosExternos}
            onChange={(e) => {
              const temp = { ...InputEngenhariaData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.ParticipacaoRecursosExternos = checked;
              setInputEngenhariaData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Participação de Recursos Externos
            </span>
          </label>
        </div>
        <div className="d-flex flex-row gap-2">
          <Field
            type="checkbox"
            name="checked5"
            checked={InputEngenhariaData.Continuado}
            onChange={(e) => {
              const temp = { ...InputEngenhariaData };
              const { name, checked } = e.target;
              setFieldValue(name, checked);
              temp.Continuado = checked;
              setInputEngenhariaData(temp);
            }}
          />
          <label className="">
            <span className="d-flex flex-row align-items-center">
              Continuado
            </span>
          </label>
        </div>
        <div className=" d-flex flex-row-reverse gap-3 mt-3">
          <Button className="btn btn-primary button " type="submit">
            Adicionar
          </Button>
        </div>
      </div>
    </>
  );
}
