import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthContext from "../../../contexts/auth";
import NavegacaoLateral from "../../NavegacaoLateral/NavegacaoLateral";
import { RouteNamesIndex } from "../../../Dictionary/RouteNamesIndex";
import { Decode } from "../../../services/Decode";
import axios from "axios";
import Select from "react-select";
import { createBrowserHistory } from "history";
import { CheckStepsCompleted } from "../../../functions/CheckStepsCompleted";
import { ModalErrorMessage } from "../../ModalErrorMessage/ModalErrorMessage";

export function InformacoesBasicas({ routeNumber }) {
  const [valueRequester, setValueRequester] = useState([]);
  const [NumDocumento, setNumDocumento] = useState("");
  const [Requisitante, setRequisitante] = useState("");
  const [load, setLoad] = useState(false);
  const [code, setCode] = useState("");
  const [Anocode, setAnoCode] = useState("");
  const { state } = useContext(AuthContext);
  const [numberRoute, setNumberRoute] = useState(0);
  const [unidadeCompradora, setUnidadeCompradora] = useState([{}]);
  const location = useLocation();
  const history = createBrowserHistory();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [tokenDecode, setTokenDecode] = useState({});
  const [steps, setSteps] = useState([]);
  const schema = yup.object().shape({
    Requisitante: yup.object().typeError("O Valor deve ser um número"),
    // .required("O número do Processo Interno é obrigatório"),
    NumDocumento: yup
      .string()
      .typeError("O Valor deve ser um texto")
      .required("O número do documento é obrigatório"),
  });

  async function getDataRisco(code) {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas/${code}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          setNumDocumento(res.data.Numero);
          setRequisitante(res.data?.CodigoUnidadeRequisitante);
          setLoad(true);
        });
      getUnidadeCompradora();
    } catch (error) {}
  }
  function getUnidadeCompradora() {
    // axios
    //   .get(
    //     `https://comprador.api.pcpdev.com.br/v1/comprador/unidades-compradora-usuario`,
    //     {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //         "Content-Type": "application/json",

    //         Authorization: `Bearer ${state.token}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     const values = res.data.map((item) => {
    //       return { label: item.nome, value: item.codigo };
    //     });

    //     setUnidadeCompradora(values);
    //   });

    setUnidadeCompradora([
      {
        label: "Unidade teste",
        value: "25432c20-02d8-46d4-98c0-3e2902e0db7a",
      },
    ]);
  }
  async function LoadData() {
    const code = searchParams.get("code");
    const ano = searchParams.get("CodigoAno");
    setAnoCode(ano);
    const decode = await Decode(state.token);

    setTokenDecode(decode);
    if (code !== null) {
      setCode(code);
      getDataRisco(code);
      setSteps(await CheckStepsCompleted(code, state.token));
    } else {
      getUnidadeCompradora();
      setLoad(true);
    }
  }
  useEffect(() => {
    LoadData();
  }, []);

  async function handleSubmit(e) {
    const routeName = RouteNamesIndex.find((item) => {
      return item.number === 2;
    });
    try {
      if (!code) {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas`,
          {
            Numero: e.NumDocumento,
            CodigoUnidadeRequisitante: "25432c20-02d8-46d4-98c0-3e2902e0db7a",
            CodigoCalendarioPca: Anocode,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );

        setCode(response.data.Codigo);

        navigate(
          `/Cadastra-DFD?etapa=${routeName.route}&CodigoAno=${Anocode}&code=${response.data.Codigo}`
        );
      } else {
        await axios
          .put(
            `${process.env.REACT_APP_BASEURL}/api/v1.0/documentos-formalizacao-demandas`,
            {
              Codigo: code,
              Numero: e.NumDocumento,
              CodigoUnidadeRequisitante: "c6cbb4a6-a632-475b-bce7-bb4e47a6caf7",
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          )
          .then(() => {
            navigate(
              `/Cadastra-DFD?etapa=${routeName.route}&CodigoAno=${Anocode}&code=${code}`
            );
          });
      }
    } catch (error) {
      ModalErrorMessage(error);
    }
  }

  return (
    <div className="d-flex flex-row gap-2">
      {load ? (
        <>
          <NavegacaoLateral
            ativo={routeNumber}
            age={Anocode}
            code={code}
            check={steps}
          />
          <div className="col-sm-9 mt-4">
            <h2>Informações básicas</h2>

            <div className="container">
              <Formik
                initialValues={{
                  NumDocumento: NumDocumento,
                  Requisitante: Requisitante
                    ? { label: "Unidade Teste", value: Requisitante }
                    : "",
                  Responsavel: tokenDecode.nameid,
                }}
                validationSchema={schema}
                validateOnBlur={false}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form className="d-flex flex-column justify-content-end  ">
                    <div className="d-flex  ">
                      <div className="col-md-6 ">
                        <FormGroup className="mb-3 p-1 formGroup">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Número do DFD
                            </span>
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="NumDocumento"
                          />
                          <ErrorMessage component="p" name="NumDocumento" />
                        </FormGroup>
                        <FormGroup className="mb-3 p-1 formGroup">
                          <label className="">
                            <span className="d-flex flex-row align-items-center">
                              Responsável pela Demanda
                            </span>
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="Responsavel"
                            disabled
                          />
                          <ErrorMessage component="p" name="NumDocumento" />
                        </FormGroup>
                      </div>

                      <div className="d-flex flex-column w-50  ">
                        <FormGroup className="mb-3 p-1 formGroup">
                          <label>
                            <span className="d-flex flex-row align-items-center">
                              Unidade requisitante
                            </span>
                          </label>
                          <Select
                            name="Requisitante"
                            options={unidadeCompradora}
                            placeholder={"selecione"}
                            value={values.Requisitante}
                            onChange={(selectedOptions) => {
                              setFieldValue("Requisitante", selectedOptions);
                            }}
                          />
                          <ErrorMessage component="p" name="Requisitante" />
                        </FormGroup>
                      </div>
                    </div>
                    <div className=" d-flex flex-row-reverse gap-3 mt-3">
                      <Button className="btn btn-primary button " type="submit">
                        Salvar e Continuar
                      </Button>
                      <Button
                        className="btn btn-warning  button "
                        type="button"
                        onClick={() => {
                          navigate("/PCA");
                        }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      ) : (
        //   <NumProcessoSkeleton />
        <></>
      )}
    </div>
  );
}
