import styles from "./CalendarPCA.scss";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { useContext, useEffect, useReducer, useState } from "react";
import api from "../../../services/api";
import { HiPencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { DataUserContext } from "../../../contexts/context";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers";
import AuthContext from "../../../contexts/auth";
import * as jose from "jose";
import axios from "axios";
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import { ModalError } from "../../../components/ModalError/ModalError";
export function CalendarPCA() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const { userData } = useContext(DataUserContext);
  const [erroPage, setErrorPage] = useState(true);
  const [RequestCalendar, setRequestCalendar] = useState([]);
  const [inicioPrevisoes, setinicioPrevisoes] = useState();
  const [fimPrevisoes, setfimPrevisoes] = useState();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleFilterAno(event) {
    let incio = "";
    let fim = "";
    if (inicioPrevisoes) {
      incio = inicioPrevisoes?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    }
    if (fimPrevisoes) {
      fim = fimPrevisoes?.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    }
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/calendarios-pca?ano=${event.AgeCalendar}&inicioPrevisoes=${incio}&fimPrevisoes=${fim}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setRequestCalendar(res?.data);
          } else {
            setRequestCalendar([]);
          }
        });
    } catch (error) {
      ModalError(error);
    }
  }
  async function handleClick() {
    navigate("/novo-Calendario");
  }

  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/calendarios-pca`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setRequestCalendar(response.data);
      }
      // setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);
  async function handleEditCalendar(Codigo) {
    navigate(`/novo-Calendario?Code=${Codigo}`);
  }
  return (
    <>
      {erroPage ? (
        <>
          <Navbar
            // subtitulo="Módulo de Planejamento"
            titulo="Plano De Contratação Anual"
            button={{ text: "+ Cadastrar Novo Ano" }}
            onClick={handleClick}
          />

          <main className="listagem-Calendario-PCA">
            <h3 className="mt-3">Calendário PCA</h3>
            <Formik
              initialValues={{
                AgeCalendar: "",
              }}
              onSubmit={handleFilterAno}
            >
              <Form>
                <div className="container pt-3   ">
                  <div className="formPCA gap-3 ">
                    <div className="col-sm">
                      <Field
                        type="text"
                        name="AgeCalendar"
                        placeholder="Ano"
                        className="form-control inputForm "
                      />
                    </div>
                    <div className="">
                      <DatePicker
                        format="DD/MM/YYYY"
                        className="datePiker"
                        label="Data de início das previsões"
                        value={inicioPrevisoes}
                        onChange={(newValue) => setinicioPrevisoes(newValue)}
                      />
                    </div>
                    <div className="">
                      <DatePicker
                        format="DD/MM/YYYY"
                        className="datePiker"
                        label="Data de fim das previsões"
                        value={fimPrevisoes}
                        onChange={(newValue) => setfimPrevisoes(newValue)}
                      />
                    </div>

                    <div className="col">
                      <button className="btn btn-primary " type="submit">
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
            <div className="container">
              <table className="table">
                <tbody>
                  <tr className={styles.linha__tabbel_listagem}>
                    <th>Ano</th>
                    <th>início das previsões</th>
                    <th>fim das previsões</th>

                    <th></th>
                  </tr>

                  {RequestCalendar?.map((item) => (
                    <tr key={item?.Codigo}>
                      <th>{item.Ano}</th>
                      <th>
                        {dayjs(item.InicioPrevisoes).format("DD/MM/YYYY")}
                      </th>
                      <th> {dayjs(item.FimPrevisoes).format("DD/MM/YYYY")}</th>

                      <td>
                        <div>
                          <HiPencil
                            type="button"
                            name="teste"
                            onClick={() => handleEditCalendar(item?.Codigo)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex flex-row-reverse">
              <Button onClick={() => navigate("/PCA")}>voltar</Button>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
