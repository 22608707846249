import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { SideNavigateContext } from "../../contexts/context";
import "./MenuRetractable.scss";
import { RouteNamesIndex } from "../../Dictionary/RouteNamesIndex";
import { FaCheck } from "react-icons/fa";

const MenuRetractable = (props) => {
  const navigationRef = useRef(null);
  function CloseMenu() {
    props.setOpenSideNavigate(true);
  }
  useEffect(() => {
    const targetElement = document.getElementById(props.ativo);

    if (targetElement) {
      const offsetTop = targetElement.offsetTop * 2;
      const navHeight = navigationRef.current.offsetHeight;
      navigationRef.current.scrollTo({
        top: offsetTop - navHeight,
        behavior: "smooth",
      });
    }
  }, [props.ativo]);
  return (
    <aside className="mt-4 aside_retractable">
      <div className="menu_retractable">
        <div className="barra_superior_retractable">
          <button className="btn btn-sm" onClick={CloseMenu}>
            <i className="fa-solid fa-angles-right"></i>
          </button>
        </div>
        <div ref={navigationRef} className="scroll_menu_retractable">
          <nav id="nav_menu" className="">
            <ul>
              <li className={props.ativo === 1 ? "ativo" : ""}>
                {props.code ? (
                  <Link
                    className="link"
                    to={`/Cadastra-DFD?etapa=${RouteNamesIndex[0].route}&CodigoAno=${props.age}&code=${props.code}`}
                  >
                    {props.check?.includes(1) ? <FaCheck /> : 1}
                  </Link>
                ) : (
                  <Link
                    className="link"
                    to={`/Cadastra-DFD?etapa=${RouteNamesIndex[0].route}&CodigoAno=${props.age}`}
                  >
                    {props.check?.includes(1) ? <FaCheck /> : 1}
                  </Link>
                )}
              </li>
              <li className={props.ativo === 2 ? "ativo" : ""}>
                <Link
                  className="link"
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[1].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  {props.check?.includes(2) ? <FaCheck /> : 2}
                </Link>
              </li>
              <li className={props.ativo === 3 ? "ativo" : ""}>
                <Link
                  className="link"
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[2].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  {props.check?.includes(3) ? <FaCheck /> : 3}
                </Link>
              </li>
              <li className={props.ativo === 4 ? "ativo" : ""}>
                <Link
                  className="link"
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[3].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  {props.check?.includes(4) ? <FaCheck /> : 4}
                </Link>
              </li>
              <li className={props.ativo === 5 ? "ativo" : ""}>
                <Link
                  className="link"
                  to={`/Cadastra-DFD?etapa=${RouteNamesIndex[4].route}&CodigoAno=${props.age}&code=${props.code}`}
                >
                  {props.check?.includes(5) ? <FaCheck /> : 5}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default MenuRetractable;
