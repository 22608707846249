import LogoBranca from "./../../assets/logo/ico-logo-portal-branco.svg";
import './rodape.scss'

const Footer = () => {

    return (
        <footer className="rodape">
            <div className="container">
            	<div className="row">
            		<div className="col-sm">
	                	<img src={LogoBranca} alt="Logo do rodapé" />
                	</div>
                	<div className="col-sm d-flex justify-content-end">
	                	<p>&copy; { (new Date()).getFullYear() } - Portal de compras públicas. Todos os Direitos Reservados</p>
                	</div>
                </div>
            </div>
        </footer>
    );

}

export default Footer;
